import React from "react";

interface ViewProps {
    title: string;
    subTitle: string;
    description?: string;
    isMinimal?: boolean;
}

export const DefaultBannerTitle: React.FunctionComponent<ViewProps> = (props) => {
    const {title, subTitle, description, isMinimal} = props;

    return (
        <React.Fragment>
            <h1 id="banner-title" className={ (isMinimal) ? 'minimal' : '' }>{title}</h1>
            <p id="banner-subtitle">{subTitle}</p>
            <div id="banner-description" className="paraf-banner" >
                { description && <p>{description}</p> }
            </div>
        </React.Fragment>
    );
}