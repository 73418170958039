import React from "react";
import {getNewsData, NewsData} from "../../Actions/NewsAction";
import {useParameterizedQuery} from "react-fetching-library";
import {useVersionState} from "../../Contexts/ApiVersionProvider";
import moment from "moment/moment";
import {NewsCard} from "./NewsCard";
import {BlogSkeleton} from "./BlogSkeleton";
import {AnimateBox} from "../../Components/GenericComponents/AnimateBox";
import {useNavigate, useParams} from "react-router-dom";
import {NewsModal} from "./NewsModal";
import {setNewsSectionData, useNewsSectionDispatch, useNewsSectionState} from "../../Contexts/NewsSectionProvider";

const falseNews : NewsData = {
    id: 0,
    title: 'Noticia no encontrada',
    description: 'La noticia que buscas no ha sido encontrada',
    content: '<h4>La noticia que buscas no ha sido encontrada</h4><p>Esto es realmente embarazoso. La noticia que usted intenta acceder no está disponible en este momento. Quizá lo estuvo en el pasado y ha sido borrado o quizá has escrito la dirección web mal. Si recibes este error, comprueba que la dirección que has introducido es correcta y no le falta o sobra nada.</p>',
    newsDate: moment().format('YYYY-MM-DDT00:00:00Z'),
    fuente: 'No Encontrado',
    link: '/',
    author: {
        id: 0,
        name: 'No Encontrado',
        image: '/page_react/images/person2.jpg'
    }
};

export const AllNews: React.FunctionComponent = () => {
    const [isLoadData, setIsLoadData] = React.useState(false);

    const [newsSelected, setNewsSelected] = React.useState<NewsData>(falseNews);
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const getNewaDataAction = useParameterizedQuery(getNewsData);
    const { data } = useVersionState()
    const version = React.useMemo( () => {
        return data?.versions?.news
    }, [data] );

    const newsSectionState = useNewsSectionState()
    const newsSectionDispatch = useNewsSectionDispatch()

    const newsSection = React.useMemo( () => {
        return newsSectionState.data
    }, [newsSectionState.data]);

    const [filterStr, setFilterStr] = React.useState<string>('');

    const newsDataList = React.useMemo( ()=>{
        return newsSection ? newsSection.news
            .filter(news => news.title.toLowerCase().includes(filterStr.toLowerCase()) || news.content.toLowerCase().includes(filterStr.toLowerCase()))
            .sort((a, b) => {
                const dateA = moment(a.newsDate);
                const dateB = moment(b.newsDate);

                return dateA.isAfter(dateB) ? -1 : dateA.isBefore(dateB) ? 1 : 0;
            }) : [];
    }, [filterStr, newsSection] )

    React.useEffect(() => {
        setTimeout( ()=>{
            const bannerTitle = document.getElementById('banner-title');
            const bannerSubtitle = document.getElementById('banner-subtitle');
            const bannerDescription = document.getElementById('banner-description');


            if( newsSection !== undefined ){
                if (bannerTitle) bannerTitle.innerHTML = newsSection.title;
                if (bannerSubtitle) bannerSubtitle.innerText = newsSection.subtitle
                if (bannerDescription) bannerDescription.innerText = newsSection.description;
            }
        }, 300 );
    }, [newsSection]);

    const NewsItems = React.useMemo( ()=>{
        return newsDataList.map( news => <NewsCard key={`news-${news.id}`} news={news} isHome={false}/> );
    } , [newsDataList] )

    const forceDownload = React.useMemo( () => {
        if (newsSection === undefined || version === undefined) {
            setIsLoadData(false);
            return true
        }

        if (newsSection.version !== version) {
            setIsLoadData(false);
            return true
        }

        setIsLoadData(true);
        return false
    }, [newsSection, version] );

    React.useEffect(() => {
        if ( (forceDownload && version) || (version && version.length > 0 && !newsSection && !isLoadData) ){
            setIsLoadData(true);

            if ( getNewaDataAction.loading ) return;

            getNewaDataAction.query(version).then(response => {
                if (response.payload) {
                    setNewsSectionData(response.payload, newsSectionDispatch).then();
                }
            });
        }
    }, [forceDownload, data, getNewaDataAction, isLoadData, newsSection, newsSectionDispatch, version]);

    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    React.useEffect( ()=>{
        setIsModalOpen(false);
        if( newsSection !== undefined && id !== undefined ){
            const news = newsSection.news.find( news => news.id+'' === id );
            if( news !== undefined ){
                setNewsSelected(news);
            }else{
                setNewsSelected(falseNews);
            }

            setIsModalOpen(true);
        }
    }, [id, navigate, newsSection] );

    if (newsSection === undefined || getNewaDataAction.loading) {
        return <BlogSkeleton />;
    }

    return (
        <React.Fragment>
            <NewsModal isOpen={ isModalOpen } news={ newsSelected } />
            <div id="fh5co-blog" className="fh5co-bg-section">
                <div className="container">
                    <AnimateBox className="row animate-box row-pb-md" data-animate-effect="fadeInUp">
                        <div className="col-sm-8 col-sm-offset-2 text-left fh5co-heading">
                            <form action="#">
                                <div className="row form-group">
                                    <div className="col-sm-12">
                                        <label htmlFor="fname">Búscas algo en especial..</label>
                                        <input type="text"
                                               id="fname"
                                               className="form-control"
                                               placeholder="Búsqueda de Noticias"
                                               onChange={ (e) => setFilterStr(e.target.value) }
                                               value={filterStr}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </AnimateBox>
                    <div className="row flex-row">
                        {NewsItems}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};