import React from "react";
import {getPartnersData} from "../../Actions/PartnersActions";
import {useParameterizedQuery} from "react-fetching-library";
import {useVersionState} from "../../Contexts/ApiVersionProvider";
import {PartnerCard} from "./PartnerCard";
import {PartnerSkeleton} from "./PartnerSkeleton";
import {
    setPartnerSectionData,
    usePartnerSectionDispatch,
    usePartnerSectionState
} from "../../Contexts/PartnersSectionProvider";

export const AllPartners: React.FunctionComponent = () => {
    const [isLoadData, setIsLoadData] = React.useState(false);

    const getPartnersDataAction = useParameterizedQuery(getPartnersData);

    const { data } = useVersionState()
    const version = React.useMemo( () => {
        return data?.versions?.partners
    }, [data] );

    const partnerSectionState = usePartnerSectionState()
    const partnerSectionDispatch = usePartnerSectionDispatch();

    const partnerSection = React.useMemo( () => {
        return partnerSectionState.data
    }, [partnerSectionState.data]);


    const partnerList = React.useMemo( ()=>{
        return partnerSection ? partnerSection.partners : [];
    }, [partnerSection] )

    React.useEffect(() => {
        setTimeout( ()=>{
            const bannerTitle = document.getElementById('banner-title');
            const bannerSubtitle = document.getElementById('banner-subtitle');
            const bannerDescription = document.getElementById('banner-description');


            if( partnerSection !== undefined ){
                if (bannerTitle) bannerTitle.innerHTML = partnerSection.title;
                if (bannerSubtitle) bannerSubtitle.innerText = partnerSection.subtitle
                if (bannerDescription) bannerDescription.innerText = partnerSection.description;
            }
        }, 300 );
    }, [partnerSection]);

    const forceDownload = React.useMemo( () => {
        if (partnerSection === undefined || version === undefined) {
            setIsLoadData(false);
            return true
        }

        if (partnerSection.version !== version) {
            setIsLoadData(false);
            return true
        }

        setIsLoadData(true);
        return false
    }, [partnerSection, version] );

    React.useEffect(() => {
        if ( (forceDownload && version) || (version && version.length > 0 && !partnerSection && !isLoadData) ){
            setIsLoadData(true);

            if ( getPartnersDataAction.loading ) return;

            getPartnersDataAction.query(version).then(response => {
                if (response.payload) {
                    setPartnerSectionData(response.payload, partnerSectionDispatch).then();
                }
            });
        }
    }, [forceDownload, data, getPartnersDataAction, isLoadData, partnerSection, partnerSectionDispatch, version]);

    const ElementOfPartner = React.useMemo( ()=>{
        return partnerList.sort((a, b) => a.order - b.order)
            .map(partner => <PartnerCard partner={partner} key={partner.id} isCarousel={false}/>);
    }, [partnerList] )

    if (partnerSection === undefined || getPartnersDataAction.loading) {
        return <PartnerSkeleton />;
    }

    return  (
        <React.Fragment>
            <div id="fh5co-project">
                <div className="container">
                    <div className="row">
                        { ElementOfPartner }
                    </div>
                </div>
            </div>
        </React.Fragment>
)
}