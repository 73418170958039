import {Section} from "./Actions";
import {Action} from "react-fetching-library";
import {ServicesUrl} from "./paths";

export interface NewsData {
    id: number;
    newsDate: string; // Formato de fecha: "YYYY-MM-DDTHH:MM:SSZ"
    title: string;
    description: string;
    fuente: string;
    link: string;
    content: string;
    author: {
        id: number;
        name: string;
        image: string;
    };
}

export interface NewsSection extends Section {
    news: NewsData[];
}

export const getNewsData  = (version: string) : Action<NewsSection> => ({
    method: 'GET',
    endpoint: `${ ServicesUrl.news }?v=${ version }`,
    body: undefined,
});