import React from "react";
import {AnimateBox} from "../../Components/GenericComponents/AnimateBox";
import {Link} from "react-router-dom";
import {useParameterizedQuery} from "react-fetching-library";
import {useVersionState} from "../../Contexts/ApiVersionProvider";
import {getNewsData, NewsData} from "../../Actions/NewsAction";
import moment from 'moment';
import {NewsCard} from "./NewsCard";
import {BlogSkeleton} from "./BlogSkeleton";
import {setNewsSectionData, useNewsSectionDispatch, useNewsSectionState} from "../../Contexts/NewsSectionProvider";

export const Blog: React.FunctionComponent = (_props) => {
    const [isLoadData, setIsLoadData] = React.useState(false);

    const getNewaDataAction = useParameterizedQuery(getNewsData);

    const { data } = useVersionState()
    const version = React.useMemo( () => {
        return data?.versions?.news
    }, [data] );

    const newsSectionState = useNewsSectionState()
    const newsSectionDispatch = useNewsSectionDispatch()

    const newsSection = React.useMemo( () => {
        return newsSectionState.data
    }, [newsSectionState.data]);

    const newsDataList = React.useMemo( ()=>{
        const newsData : NewsData[] = []

        const newsSorted = newsSection ? newsSection.news.sort((a, b) => {
            const dateA = moment(a.newsDate);
            const dateB = moment(b.newsDate);

            return dateA.isAfter(dateB) ? -1 : dateA.isBefore(dateB) ? 1 : 0;
        } ) : [];

        for ( let i = 0; i < newsSorted.length && i < 3; i++ ) {
            newsData.push(newsSorted[i]);
        }

        return newsData;
    }, [newsSection] )

    const NewsItems = React.useMemo( ()=>{
        return newsDataList.map( news => <NewsCard key={`news-${news.id}`} news={news} isHome={true}/> );
    } , [newsDataList] )

    const forceDownload = React.useMemo( () => {
        if (newsSection === undefined || version === undefined) {
            setIsLoadData(false);
            return true
        }

        if (newsSection.version !== version) {
            setIsLoadData(false);
            return true
        }

        setIsLoadData(true);
        return false
    }, [newsSection, version] );

    React.useEffect(() => {
        if ( (forceDownload && version) || (version && version.length > 0 && !newsSection && !isLoadData) ){
            setIsLoadData(true);

            if ( getNewaDataAction.loading ) return;

            getNewaDataAction.query(version).then(response => {
                if (response.payload) {
                    setNewsSectionData(response.payload, newsSectionDispatch).then();
                }
            });
        }
    }, [forceDownload, data, getNewaDataAction, isLoadData, newsSection, newsSectionDispatch, version]);

    if (newsSection === undefined || getNewaDataAction.loading) {
        return <BlogSkeleton />;
    }

    return (
        <div id="fh5co-blog" className="fh5co-bg-section">
            <div className="container">
                <AnimateBox className="row animate-box row-pb-md" data-animate-effect="fadeInUp">
                    <div className="col-md-8 col-md-offset-2 text-left fh5co-heading">
                        <span>{newsSection.subtitle}</span>
                        <h2>{newsSection.title}</h2>
                        <p>{newsSection.description}</p>

                            <Link
                                to={"/noticias/ver-todas"}
                                className="btn btn-primary"
                                onClick={ _e => {
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                } }
                            >
                                Ver todas
                            </Link>

                    </div>
                </AnimateBox>
                <div className="row flex-row">
                    { NewsItems }
                </div>
            </div>
        </div>
    );
}