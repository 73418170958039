import React from "react";
import {AnimateBox} from "../../Components/GenericComponents/AnimateBox";
import {MagazineData} from "../../Actions/MagazineActions";
import Moment from "react-moment";
import {Link} from "react-router-dom";

interface ViewProps {
    item: MagazineData
}
export const SelectedMagazine: React.FunctionComponent<ViewProps> = (props) => {
    const {item} = props;
    return (
        <AnimateBox className="row animate-box row-pb-md" data-animate-effect="fadeInUp">
            <div className="row">
                <div className="col-sm-6 col-md-4">
                    <img src={`${item.cover}`} className="img-responsive" alt={`Revista Aieja ${item.name}`}/>
                </div>
                <div className="col-sm-6 col-md-8">
                    <h3>Revista Aieja {item.name}</h3>
                    <p>{item.description}</p>
                    <div className="row">
                        <div className="col-sm-6 col-md-4">
                            <strong>Fecha (Año / Mes):</strong>
                        </div>
                        <div className="col-sm-6 col-md-8">
                            <Moment format="YYYY / MMMM">{item.date}</Moment>
                        </div>
                    </div>
                    {item.articles.length > 0 && (
                        <div className="row">
                            <div className="col-sm-6 col-md-4">
                                <strong>Artículos Destacados</strong>
                            </div>
                            <div className="col-sm-6 col-md-8">
                                <ul style={{paddingLeft: 15}}>
                                    {item.articles.map((article, index) => (
                                        <li key={index}>{article}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>

                <div className="col-sm-6 col-md-8" style={{marginTop: 25}}>
                    <Link to={`/revista/${ item.id }`} className="btn btn-primary">Leer Revista</Link>
                    <a rel="noreferrer" target="_blank" href={item.pdf} className="btn btn-default">Descargar PDF</a>
                </div>
            </div>
        </AnimateBox>
    )
}