import {Section} from "./Actions";
import {Action} from "react-fetching-library";
import {ServicesUrl} from "./paths";

export interface StateData {
    id: string;
    title: string;
    className: string;
    d: string;
    count: number;
}

export interface CasinosSection extends Section{
    map: StateData[]
}

export const getCasinos = (version: string) : Action<CasinosSection> => ({
    method: 'GET',
    endpoint: `${ ServicesUrl.casinos }?v=${ version }`,
    body: undefined,
});