import {Section} from "./Actions";
import {Action} from "react-fetching-library";
import {ServicesUrl} from "./paths";

export interface PartnerData {
    id: number;
    order: number;
    name: string;
    url: string;
    image: string;
}

export interface PartnerSection extends Section {
    partners: PartnerData[];
}

export const getPartnersData  = (version: string) : Action<PartnerSection> => ({
    method: 'GET',
    endpoint: `${ ServicesUrl.partners }?v=${ version }`,
    body: undefined,
});