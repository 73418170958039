import {Section} from "./Actions";
import {Action} from "react-fetching-library";
import {ServicesUrl} from "./paths";

export interface CommissionsData {
    title: string;
    description: string;
    vicepresident: string;
    date: string;
    approved: string;
}

export interface CommissionsSection extends Section {
    commissions: CommissionsData[];
}

export const getCommissionsData  = (version: string) : Action<CommissionsSection> => ({
    method: 'GET',
    endpoint: `${ ServicesUrl.commissions }?v=${ version }`,
    body: undefined,
});