import React from "react";
import {BiografyHealingMexico} from "./HealingMexico";

interface ViewProps {
    testimonial: BiografyHealingMexico;
    isSelected: boolean;
}

export const Testimonial: React.FunctionComponent<ViewProps> = (props) => {
    const {testimonial, isSelected} = props;

    return (
        <div className={`testimonial${ isSelected ? ' fh5co-selected' : '' }`}>
            <blockquote>
                <h3>{ testimonial.title }</h3>
                <p>&ldquo;{ testimonial.comment }&rdquo;</p>
                <p>{testimonial.editorial}</p>
                <p className="author">
                    { testimonial.authors.map((author, index) => (
                        <React.Fragment key={`author-${testimonial.id}-${index}`}>
                            <cite key={`author-${testimonial.id}-${index}`}>&mdash; {author}</cite><br />
                        </React.Fragment>
                    ) )}
                </p>
            </blockquote>
        </div>
    )
}