import React from "react";
import {useParameterizedQuery} from "react-fetching-library";
import {getMagazine, MagazineData} from "../../Actions/MagazineActions";
import {
    setMagazineSectionData,
    useMagazineSectionDispatch,
    useMagazineSectionState
} from "../../Contexts/MagazineSectionProvider";
import {useVersionState} from "../../Contexts/ApiVersionProvider";
import {AnimateBox} from "../../Components/GenericComponents/AnimateBox";
import moment from "moment";
import {MagazineCard} from "./MagazineCard";
import {SelectedMagazine} from "./SelectedMagazine";
import {MagazineVisor} from "./MagazineVisor";
import {useLocation, useParams} from "react-router-dom";

// <PDFFlipbook pdfUrl={'/page_react/magazine/AiejaMagazine29.pdf'} />
export const Magazine : React.FunctionComponent = () => {
    const [isLoadData, setIsLoadData] = React.useState(false);
    const {magazineID} = useParams()

    const getMagazineAction = useParameterizedQuery(getMagazine);

    const { data } = useVersionState()

    const version = React.useMemo( () => {
        return data?.versions?.magazine
    }, [data] );

    const sectionState = useMagazineSectionState()
    const sectionDispatch = useMagazineSectionDispatch()

    const [selectedMagazine, setSelectedMagazine] = React.useState<MagazineData>();
    const [openModal, setOpenModal] = React.useState<boolean>(false);

    const magazineSection = React.useMemo( ()=>{
        return sectionState.data
    } ,[sectionState.data] )

    const [filterStr, setFilterStr] = React.useState<string>('');
    const [filterYear, setFilterYear] = React.useState<number>(0);

    const stateData = React.useMemo(()=>{
        if ( magazineSection ) {
            let list = magazineSection.magazine_list.sort((a, b) => {
                const dateA = moment(a.date);
                const dateB = moment(b.date);

                return dateA.isAfter(dateB) ? -1 : dateA.isBefore(dateB) ? 1 : 0;
            });

            if (filterYear !== 0){
                list = list.filter( state => {
                    const momentDate = moment(state.date);
                    const year = momentDate.year();
                    return year === filterYear
                } )
            }

            const filteredList = list.filter( state => {
                let initialFlag = state.name.toLowerCase().includes(filterStr.toLowerCase()) || state.description.toLowerCase().includes(filterStr.toLowerCase())
                if (initialFlag) return true;

                state.articles.forEach( article => {
                    initialFlag = initialFlag || article.toLowerCase().includes(filterStr.toLowerCase())
                } )

                return initialFlag
            } );

            if (filteredList.length > 0) {
                setSelectedMagazine(filteredList[0]);
            }else {
                setSelectedMagazine(undefined);
            }

            return filteredList
        }

        return [];
    }, [filterStr, magazineSection, filterYear])

    const ITEMS = React.useMemo( () => {
        const selectedId = selectedMagazine ? selectedMagazine.id : -1;
        return stateData.map( item => (
            <MagazineCard key={`carg-off-maggazine-${item.id}`}
                          item={item}
                          isSelected={ item.id === selectedId }
                          setIsSelected={ ()=>{ setSelectedMagazine(item) } }
            />
        ) )
    }, [stateData, selectedMagazine] )

    const SELECTED_MAGAZINE = React.useMemo( () => {
        if (selectedMagazine === undefined) return null;

        return (
            <div className="preview-container" >
                <div className="container">
                    <SelectedMagazine item={selectedMagazine} />
                </div>
            </div>
        )
    }, [selectedMagazine] )

    const YEAR_OPTIONS = React.useMemo( () => {
        const dataOptions = [ {label: 'Ver Todas', value:0 } ]
        const vals = [0]

        if (magazineSection) {
            magazineSection.magazine_list.forEach( state => {
                const momentDate = moment(state.date);
                const year = momentDate.year();

                if( !vals.includes(year) ){
                    vals.push(year)
                    dataOptions.push( {label: `Año ${year.toString()}`, value: year} )
                }
            })
        }

        return dataOptions.map( option => <option key={`year-option-${option.value}`} value={option.value} selected={ option.value === filterYear }>{option.label}</option> );
    } , [magazineSection, filterYear] )

    const forceDownload = React.useMemo( () => {
        if (magazineSection === undefined || version === undefined) {
            setIsLoadData(false);
            return true
        }

        if (magazineSection.version !== version) {
            setIsLoadData(false);
            return true
        }

        setIsLoadData(true);
        return false
    }, [magazineSection, version] );

    React.useEffect(() => {
        if( (forceDownload && version) || (version && version.length > 0 && !magazineSection && !isLoadData) ){
            setIsLoadData(true);

            if ( getMagazineAction.loading ) return;

            getMagazineAction.query(version).then(response => {
                if (response.payload) {
                    setMagazineSectionData(response.payload, sectionDispatch).then();
                }
            });
        }
    }, [forceDownload, data, isLoadData, version, sectionDispatch, magazineSection, getMagazineAction]);

    React.useEffect(() => {
        setTimeout( ()=>{
            const bannerTitle = document.getElementById('banner-title');
            const bannerSubtitle = document.getElementById('banner-subtitle');
            const bannerDescription = document.getElementById('banner-description');


            if( magazineSection !== undefined ){
                if (bannerTitle) bannerTitle.innerHTML = magazineSection.title;
                if (bannerSubtitle) bannerSubtitle.innerText = magazineSection.subtitle
                if (bannerDescription) bannerDescription.innerText = magazineSection.description;
            }
        }, 300 );
    }, [magazineSection]);

    React.useEffect(() => {
        if(magazineID !== undefined && magazineSection !== undefined){
            if ( selectedMagazine && magazineID !== selectedMagazine.id + '' ){
                const selected = magazineSection.magazine_list.find( item => item.id === parseInt(magazineID) );
                if (selected) setSelectedMagazine(selected);
            }

            setOpenModal(true)
        }else{
            setOpenModal(false)
        }

    }, [magazineID, magazineSection, selectedMagazine]);

    const location = useLocation()
    const scrollTo = React.useCallback((selectorId: string) => {
        setTimeout(() => {
            const element = document.getElementById(selectorId);
            if (element) {
                element.scrollIntoView({behavior: 'smooth'});
            }
        }, 50);
    }, []);

    React.useEffect(() => {
        if (location.pathname === '/revista') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [location.pathname, scrollTo]);

    const VISOR = React.useMemo( () => {
        if (selectedMagazine === undefined) return null;
        return <MagazineVisor openModal={openModal} item={selectedMagazine}/>
    }, [selectedMagazine, openModal] )

    if ( magazineSection === undefined || getMagazineAction.loading) return null;

    return (
        <React.Fragment>
            { VISOR }
            <div id="fh5co-blog" className="fh5co-bg-section fh5co-bg-no-padding-top">
                {SELECTED_MAGAZINE}
                <div className="container">
                    <AnimateBox className="row animate-box row-pb-md" data-animate-effect="fadeInUp">
                        <form action="#">
                            <div className="col-sm-4 text-left fh5co-heading">
                                <div className="row form-group">
                                    <div className="col-sm-12">
                                        <label htmlFor="fname">Año</label>
                                        <select id="fyear" className="form-control" onChange={ (e) => setFilterYear( parseInt(e.target.value ) ) }>
                                            {YEAR_OPTIONS}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-8 text-left fh5co-heading">
                                <div className="row form-group">
                                    <div className="col-sm-12">
                                        <label htmlFor="fname">Búscas algo en especial..</label>
                                        <input type="text"
                                               id="fname"
                                               className="form-control"
                                               placeholder="Búsqueda de Noticias"
                                               onChange={(e) => setFilterStr(e.target.value)}
                                               value={filterStr}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </AnimateBox>

                    <div className="row flex-row">
                        {ITEMS}
                    </div>
                </div>
            </div>
        </React.Fragment>
);
}