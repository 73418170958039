import {Section} from "./Actions";
import {Action} from "react-fetching-library";
import {ServicesUrl} from "./paths";

export interface MagazineData {
    id: number;
    name: string;
    description: string;
    date: string;
    pdf: string;
    cover: string;
    articles: string[];
}

export interface MagazineSection extends Section{
    magazine_list: MagazineData[]
}

export const getMagazine = (version: string) : Action<MagazineSection> => ({
    method: 'GET',
    endpoint: `${ ServicesUrl.magazine }?v=${ version }`,
    body: undefined,
});