
import React from "react";
import {VersionData} from "./ApiVersionProvider";
import {MagazineSection} from "../Actions/MagazineActions";

interface MagazineSectionDispatcher {
    type?: "SUCCESS" | "ERROR",
    payload?: MagazineSectionPayload
}

interface MagazineSectionPayload {
    data?: MagazineSection,
    loading?: boolean,
    errorMessage?: string,
    isLoaded?: boolean
}


// - Actions
export async function setMagazineSectionData( data: MagazineSection, dispatcher: React.Dispatch<MagazineSectionDispatcher>) {
    localStorage.setItem('magazineSection', JSON.stringify(data));
    dispatcher({ type: 'SUCCESS', payload: { data: data } });
}

const initialState: MagazineSectionPayload = {
    data: undefined,
    loading: false,
    errorMessage: '',
    isLoaded: false
}

const sectionData = localStorage.getItem('magazineSection');
if (sectionData) {
    let localVersionData = localStorage.getItem('versions');
    if ( localVersionData) {
        const localData : VersionData = JSON.parse(localVersionData);
        if(localData.versions && localData.versions.casinos ){
            const sectionDataDataObj: MagazineSection = JSON.parse(sectionData);
            if (sectionDataDataObj.version === localData.versions.magazine) {
                initialState.data = sectionDataDataObj;
                initialState.isLoaded = true;
            }
        }
    }
}

let sectionReducer = (state: MagazineSectionPayload, action: MagazineSectionDispatcher): MagazineSectionPayload => {
    switch (action.type) {
        case 'SUCCESS':
            return { ...state, loading: false, data: action.payload?.data, isLoaded: true };
        case 'ERROR':
            return { ...state, loading: false, errorMessage: action.payload?.errorMessage };
        default:
            return state;
    }
}




const MagazineSectionStateContext = React.createContext<MagazineSectionPayload>({})
const MagazineSectionDispatchContext = React.createContext< React.Dispatch<MagazineSectionDispatcher> >(() => null)

export function useMagazineSectionState(){
    const context = React.useContext(MagazineSectionStateContext)
    if (context === undefined) {
        throw new Error("useAppsState must be used within a DiazarSuiteProvider");
    }

    return context;
}

export function useMagazineSectionDispatch(){
    const context = React.useContext(MagazineSectionDispatchContext)
    if (context === undefined) {
        throw new Error("useAppsDispatch must be used within a DiazarSuiteProvider");
    }

    return context;
}

interface ViewProps {
    children?: React.ReactNode | undefined
}

export const MagazineSectionProvider: React.FunctionComponent<ViewProps> = (props) => {
    const { children } = props;
    const [state, dispatch] = React.useReducer(sectionReducer, initialState);

    return (
        <MagazineSectionStateContext.Provider value={state}>
            <MagazineSectionDispatchContext.Provider value={dispatch}>
                { children }
            </MagazineSectionDispatchContext.Provider>
        </MagazineSectionStateContext.Provider>
    )
}

