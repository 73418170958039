import React from "react";
import {AnimateBox} from "../../../Components/GenericComponents/AnimateBox";
import {WhoWeAreCard} from "./WhoWeAreCard";
import {WhoWeAreModal} from "./WhoWeAreModal";
import {getWhoWheAre, WhoWheAreCardData} from "../../../Actions/WhoWeAreActions";
import {useParameterizedQuery} from "react-fetching-library";
import {NullWhoWeAre} from "./NullWhoWeAre";
import {useVersionState} from "../../../Contexts/ApiVersionProvider";
import {
    setWhoWheAreSectionData,
    useWhoWheAreSectionDispatch,
    useWhoWheAreSectionState
} from "../../../Contexts/WhoWheAreSectionProvider";
import Affix from "react-simple-affix";

interface ViewProps {
    isOpenModal: boolean;
    tagModal: string;
}

export const WhoWeAre: React.FunctionComponent<ViewProps> = (props) => {
    const {isOpenModal, tagModal} = props;
    const [isLoadData, setIsLoadData] = React.useState(false);

    //const [whoWeAre, setWhoWeAre] = React.useState<WhoWheAreSection>();
    const [selectedCard, setSelectedCard] = React.useState<WhoWheAreCardData>( {} as WhoWheAreCardData );

    const getWhoWheAreAction = useParameterizedQuery(getWhoWheAre);

    const { data } = useVersionState()
    const version = React.useMemo( () => {
        return data?.versions?.whoWeAre
    }, [data] );

    const whoWeAreState = useWhoWheAreSectionState()
    const whoWeAreDispatch = useWhoWheAreSectionDispatch();

    const whoWeAre = React.useMemo( () => {
        console.log(whoWeAreState.data)
        return whoWeAreState.data
    }, [whoWeAreState.data] );

    const forceDownload = React.useMemo( () => {
        if (whoWeAre === undefined || version === undefined) {
            setIsLoadData(false);
            return true
        }

        if (whoWeAre.version !== version) {
            setIsLoadData(false);
            return true
        }

        setIsLoadData(true);
        return false
    }, [whoWeAre, version] );

    React.useEffect(()=>{
        if ((forceDownload && version) || (version && version.length > 0 && !whoWeAre && !isLoadData)) {
            console.log(version)
            setIsLoadData(true);

            if ( getWhoWheAreAction.loading ) return;
            getWhoWheAreAction.query(version).then(response => {
                if (response.payload) {
                    setWhoWheAreSectionData(response.payload, whoWeAreDispatch).then();
                    //setWhoWeAre (response.payload);
                }
            });
        }
    }, [whoWeAre, isLoadData, getWhoWheAreAction, version, whoWeAreDispatch, forceDownload]);

    React.useEffect( () => {
        if (tagModal && whoWeAre) {
            switch (tagModal) {
                case 'mision':
                    setSelectedCard(whoWeAre.mission);
                    break;
                case 'vision':
                    setSelectedCard(whoWeAre.vision);
                    break;
                case 'nuestro-presidente':
                    setSelectedCard(whoWeAre.president);
                    break;

                default:
                    setSelectedCard(
                        {
                            title: 'Contenido no encontrado',
                            short: 'Error',
                            large: 'Error'
                        }
                    );
                    break;
            }
        }
    }, [tagModal, whoWeAre])

    if ( whoWeAre === undefined || getWhoWheAreAction.loading ) {
        return <NullWhoWeAre />
    }

    return (
        <React.Fragment>
            <WhoWeAreModal isOpen={isOpenModal} tag={tagModal} card={ selectedCard } />
            <div id="fh5co-who-we-are" className="fh5co-bg-section border-bottom">
                <div className="container-fluid">
                    <div id="who-we-are-content" className="col-md-8 col-md-offset-2">
                        <div className="row row-pb-md">
                            <AnimateBox className="col-md-8 col-md-offset-2 text-left animate-box">
                                <div className="fh5co-heading">
                                    <span>{ whoWeAre.subtitle }</span>
                                    <h2>{ whoWeAre.title }</h2>
                                    <p>{ whoWeAre.description }</p>
                                </div>
                            </AnimateBox>
                        </div>
                        <div className="row">
                            <WhoWeAreCard
                                title={ whoWeAre.mission.title }
                                tag="mision"
                                id="fh5co-who-we-are-mision"
                                className="col-sm-6"
                                icon="command"
                            >
                                <div dangerouslySetInnerHTML={{__html: whoWeAre.mission.short}} />
                            </WhoWeAreCard>

                            <WhoWeAreCard
                                title={whoWeAre.vision.title}
                                tag="vision"
                                id="fh5co-who-we-are-vision"
                                className="col-sm-6 "
                                icon="eye"
                            >
                                <div dangerouslySetInnerHTML={{__html: whoWeAre.vision.short}}/>
                            </WhoWeAreCard>

                            <div className="row"></div>
                            <WhoWeAreCard
                                title={ whoWeAre.president.title }
                                tag="nuestro-presidente"
                                className="col-sm-8 col-sm-offset-2"
                                id="fh5co-who-we-are-nuestro-presi"
                                styleTitle={{textAlign: 'center'}}
                            >
                                <div dangerouslySetInnerHTML={{__html: whoWeAre.president.short}}/>
                            </WhoWeAreCard>

                        </div>
                    </div>

                    {
                        (whoWeAre.podcast && whoWeAre.podcast.short.length > 0) && (
                            <div className="col-md-2 hidden-sm hidden-xs">
                                <Affix relativeElementSelector="#who-we-are-content" fixedFooterSelector="#fh5co-nav"
                                       topOffset={100}>
                                    <a
                                        href={whoWeAre.podcast.short}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img className="img-responsive" src="/page_react/images/assets/AiejaPodcast.png"
                                             alt="Logo AIEJA"/>
                                    </a>
                                </Affix>
                            </div>
                        )
                    }

                </div>
            </div>
        </React.Fragment>
    );
}