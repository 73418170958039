import {useNavigate} from "react-router-dom";
import React from "react";
import {WhoWheAreCardData} from "../../../Actions/WhoWeAreActions";

interface ViewProps {
    isOpen: boolean,
    tag: string,
    card: WhoWheAreCardData
}

export const WhoWeAreModal: React.FunctionComponent<ViewProps> = (props) => {
    const {isOpen, tag, card} = props;
    const navigate = useNavigate();

    const closeModal = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate(`/quienes-somos/${tag}`);
    }, [navigate, tag])

    const title = React.useMemo(() => {
       return card.title
    }, [card.title])

    const contentStr = React.useMemo(() => {
        return card.large;
    }, [card.large])

    if (!isOpen) return null

    return (
        <div id="info-modal" className={`modal fade${isOpen ? ' in' : ''}`} tabIndex={-1} role="dialog"
             style={isOpen ? {display: "block"} : undefined}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={closeModal}><span
                            aria-hidden="true">&times;</span></button>
                        <h4 className="modal-title" dangerouslySetInnerHTML={{__html:title}} />
                    </div>
                    <div className="modal-body">
                        <div dangerouslySetInnerHTML={{__html: contentStr}}/>
                    </div>
                </div>
            </div>

        </div>
    )
}