import React from "react";
import {AnimateBox} from "../../../Components/GenericComponents/AnimateBox";
import {WhoWeAreCard} from "./WhoWeAreCard";
import {Skeleton} from "../../../Components/Skeleton";

export const NullWhoWeAre : React.FunctionComponent = () => {
    return (
        <React.Fragment>
            <div id="fh5co-who-we-are" className="fh5co-bg-section border-bottom">
                <div className="container">
                    <div className="row row-pb-md">
                        <AnimateBox className="col-md-8 col-md-offset-2 text-left animate-box">
                            <div className="fh5co-heading">
                                <Skeleton type="text_title"/>
                            </div>
                        </AnimateBox>
                    </div>
                    <div className="row">
                        <WhoWeAreCard
                            title="Misión"
                            tag="mision"
                            id="fh5co-who-we-are-mision"
                            className="col-sm-6"
                            icon="command"
                            noMore
                        >
                            <Skeleton />
                        </WhoWeAreCard>

                        <WhoWeAreCard
                            title="Visión"
                            tag="vision"
                            id="fh5co-who-we-are-vision"
                            className="col-sm-6 "
                            icon="eye"
                            noMore
                        >
                            <Skeleton />
                        </WhoWeAreCard>

                        <div className="row"></div>
                        <WhoWeAreCard
                            title="Nuestro presidente"
                            tag="nuestro-presidente"
                            className="col-sm-8 col-sm-offset-2"
                            id="fh5co-who-we-are-nuestro-presi"
                            styleTitle={{textAlign: 'center'}}
                            noMore
                        >
                            <Skeleton />

                        </WhoWeAreCard>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};