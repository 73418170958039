import {AnimateBox} from "../../Components/GenericComponents/AnimateBox";
import React, {CSSProperties} from "react";
import {PartnerData} from "../../Actions/PartnersActions";

interface ViewProps {
    partner: PartnerData;
    isCarousel?: boolean
}

export const PartnerCard: React.FunctionComponent<ViewProps> = (props) => {
    const {partner} = props
    const style : CSSProperties = {
        backgroundImage: `url(${partner.image})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#efeeee',
        width: '100%',
        aspectRatio: '1/1'
    }

    const isCarousel = React.useMemo( ()=>{
        if ( props.isCarousel !== undefined ){
            return props.isCarousel
        }
        return true
    }, [props.isCarousel] )

    const element = React.useMemo( () => {
        const className = (partner.url !== undefined && partner.url !== '') ? '' : 'disabled'
        return (
            <a className={className}
                referrerPolicy={'no-referrer'}
               target={'_blank'}
               href={partner.url}
               onClick={ (e) => {
                 if (!(partner.url !== undefined && partner.url !== '')) {
                     e.preventDefault();
                 }
               }}
            >
                <div className="partner-img-container" style={style}></div>

                <div className="fh5co-copy">
                    <h3>{partner.name}</h3>
                    <p>{partner.url}</p>
                </div>
            </a>
        )
    }, [partner.id, partner.name, partner.url, style])

    return (
        <AnimateBox key={partner.id}
                    className={`${(!isCarousel) ? 'col-md-4 col-sm-6 ' : 'carousel-item'} fh5co-project animate-box fh5co-project-card`}
                    data-animate-effect="fadeIn">
            {element}
        </AnimateBox>
    );
}