import React from "react";
import {useScrollNavBar} from "./useEffects";
import {OffcanvasMenu} from "./GenericComponents/OffcanvasMenu";
import {useNavigate} from "react-router-dom";
import {ItemMenu} from "./GenericComponents/ItemMenu";

export interface LinkNav {
    id: number;
    name: string;
    href: string;
    children?: LinkNav[];
}

export const NavbarLinks : LinkNav[] = [
    {
        id: 1,
        name: "Inicio",
        href: "/"
    },
    {
        id: 2,
        name: "Quienes Somos",
        href: "/quienes-somos",
        children: [
            {
                id: 21,
                name: "Vision",
                href: "/quienes-somos/vision",
            },
            {
                id: 22,
                name: "Mission",
                href: "/quienes-somos/mision",
            },
            {
                id: 23,
                name: "Nuestro Presidente",
                href: "/quienes-somos/nuestro-presidente",
            }
        ]
    },
    {
        id: 3,
        name: "Socios",
        href: "/socios"
    },
    {
        id: 4,
        name: "Noticias",
        href: "/noticias"
    },
    {
        id: 5,
        name: "Actividades AIEJA",
        href: "/actividades",
        children: [
            {
                id: 51,
                name: "Responsabilidad social",
                href: "/actividades/responsabilidad-social",
            },
            {
                id: 52,
                name: "Comisiones",
                href: "/actividades/comisiones",
            },
            {
                id: 53,
                name: "Notas AIEJA",
                href: "/actividades/notas-aieja",
            },
            {
                id: 54,
                name: "Casinos",
                href: "/actividades/casinos",
            }
        ]
    },
    {
        id: 6,
        name: "Revista AIEJA",
        href: "/revista"
    },
    {
        id: 7,
        name: "Contacto",
        href: "contactanos"
    }
]

export const Navbar: React.FunctionComponent = (_props) => {
    const [hasClass, setHasClass] = React.useState(false);

    useScrollNavBar();

    const navigate = useNavigate()

    React.useEffect(() => {
        const checkBodyClass = () => {
            setHasClass(document.body.classList.contains('scrolled'));
        };

        const observer = new MutationObserver(checkBodyClass);

        observer.observe(document.body, {attributes: true, attributeFilter: ['class']});

        checkBodyClass();
        return () => observer.disconnect();
    }, []);

    const image = React.useMemo(() => {
        if (hasClass) {
            return "/page_react/images/assets/LogoAiejaVector.svg"
        }

        return "/page_react/images/assets/LogoAiejaVector_2.svg"
    }, [hasClass])

    const navigateTo = React.useCallback((e: React.MouseEvent<HTMLAnchorElement>, to: string) => {
        e.preventDefault();

        // Asegúrate de que la ruta esté bien formada
        const formattedTo = to.startsWith("/") ? to : `/${to}`;

        navigate(formattedTo);
    }, [navigate])

    const linksNav = React.useMemo(() => {
        return NavbarLinks.map((item) => {
            return <ItemMenu link={item} key={item.id} onClick={navigateTo}/>
        })
    }, [navigateTo])

    return (
        <React.Fragment>
            <OffcanvasMenu hasClass={hasClass} onClick={navigateTo}/>
            <nav id="fh5co-nav" className="fh5co-nav" role="navigation">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-2 text-left">
                            <div id="fh5co-logo">
                                <img id="img-logo-nav" src={image} alt="Logo"/>
                            </div>
                        </div>
                        <div className="col-xs-10 text-right menu-1">
                            <ul>
                                { linksNav }
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    );
}