import React from "react";
import {AnimateBox} from "../../../Components/GenericComponents/AnimateBox";
import {Testimonial} from "./Testimonial";

export interface BiografyHealingMexico {
    id: number;
    title: string;
    comment: string;
    authors: string[];
    editorial: string;
}

const healingMexico: BiografyHealingMexico[] = [
    {
        id: 1,
        title: "LUDOPATÍA Y RELACIONES FAMILIARES",
        comment: "Interesa este libro, pues aborda de manera exhaustiva la ludopatía. Analiza el ámbito familiar, la relación de pareja, la situación con jóvenes. Revisa factores etiológicos y terapéuticos.",
        authors: ["Miguel Garrido","Pedro Jaén", "Ana Domínguez"],
        editorial: "Paidós. Psicología Psiquiatría Psicoterapia No. 218, Barcelona, 2004"
    },
    {
        id: 2,
        title: "TERAPIA COGNITIVA DE LAS DROGODEPENDENCIAS",
        comment: "En el caso de este otro, aunque no es de ludopatía -como tal- es un medio de capacitación en el abordaje de la ludopatía como adicción, pues el enfoque que más se ha utilizado para tratamiento en el CONADIC es el COGNITIVO-CONDUCTUAL y este libro lo revisa. Interesa para saber de qué están hablando y así llevar las “sinergias” de trabajo a los terrenos de beneficio para la Asociación y la Fundación.",
        authors: ["Aaaron T. Beck", "Fred D. Wright", "Cory F. Newman", "Bruce S. Liese"],
        editorial: "Paidós. No. 190. 4ª edición, Barcelona, 2010"
    },
    {
        id: 3,
        title: "JUEGO PATOLÓGICO",
        comment: "Este libro ofrece una visión actualizada del juego patológico y propone de forma precisa y práctica un programa psicológico de intervención para dejar de jugar. Además hace una revisión histórica de la eficacia de técnicas y de los modelos terapéuticos utilizados para el tratamiento de la ludopatía.",
        authors: ["Francisco Javier Labrador", "Ana Fernández Alba"],
        editorial: "Editorial Síntesis. 1ª edición, Madrid, 2008"
    }
]

interface GorupedTestimonials {
    testimonials1: BiografyHealingMexico[];
    testimonials2: BiografyHealingMexico[];
}
export const HealingMexico: React.FunctionComponent = () => {
    const groupedTestimonials: GorupedTestimonials = React.useMemo(() => {
        const groups:GorupedTestimonials =  {
            testimonials1: [],
            testimonials2: []
        }

        for (let i = 0; i < healingMexico.length; i+=2) {
            groups.testimonials1.push(healingMexico[i]);

            if (healingMexico[i+1] !== undefined) {
                groups.testimonials2.push(healingMexico[i]);
            }
        }

        return groups
    }, []);

    const TestimonialsContent = React.useMemo(() => {
        return [
            <AnimateBox className="col-md-6 animate-box" key="right-testimonial">
                {groupedTestimonials.testimonials1.map( (testimonial, index) => <Testimonial key={`testimonial_r_${index}`} testimonial={testimonial} isSelected={ index % 2 !== 0 } /> )}
            </AnimateBox>,
            <AnimateBox className="col-md-6 animate-box" key="left-testimonial">
                {groupedTestimonials.testimonials2.map( (testimonial, index) => <Testimonial key={`testimonial_l_${index}`} testimonial={testimonial} isSelected={ index % 2 === 0 } /> )}
            </AnimateBox>
        ]
    }, [groupedTestimonials] )
    return (
        <React.Fragment>
            <div id="fh5co-testimonial" className="fh5co-bg-section">
                <div className="container">
                    <AnimateBox className="row animate-box row-pb-md">
                        <div className="col-md-8 col-md-offset-2 text-left fh5co-heading">
                            <span>Bibliografía</span>
                            <h2>Responsabilidad social</h2>
                            <p>En AIEJA promovemos una industria del juego responsable y comprometida con el bienestar social. Fomentamos el cumplimiento normativo, apoyamos el desarrollo económico y trabajamos en iniciativas que beneficien tanto al sector como a la comunidad. Creemos en un equilibrio donde el entretenimiento impulse progreso, respeto y sostenibilidad</p>
                        </div>
                    </AnimateBox>
                    <div className="row">
                        { TestimonialsContent }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}