import {ServicesUrl} from "./paths";
import {Action} from "react-fetching-library";
import {Section} from "./Actions";

export interface WhoWheAreCardData{
    title: string;
    short: string;
    large: string;
}

export interface WhoWheAreSection extends Section {
    mission: WhoWheAreCardData;
    vision: WhoWheAreCardData;
    president: WhoWheAreCardData;
    podcast: WhoWheAreCardData;
}

export const getWhoWheAre  = (version: string) : Action<WhoWheAreSection> => ({
    method: 'GET',
    endpoint: `${ ServicesUrl.whoWeAre }?v=${ version }`,
    body: undefined,
});