import {WhoWheAreSection} from "../Actions/WhoWeAreActions";
import React from "react";
import {VersionData} from "./ApiVersionProvider";

interface WhoWheAreSectionDispatcher {
    type?: "SUCCESS" | "ERROR",
    payload?: WhoWheAreSectionPayload
}

interface WhoWheAreSectionPayload {
    data?: WhoWheAreSection,
    loading?: boolean,
    errorMessage?: string,
    isLoaded?: boolean
}

// - Actions
export async function setWhoWheAreSectionData( data: WhoWheAreSection, dispatcher: React.Dispatch<WhoWheAreSectionDispatcher>) {
    localStorage.setItem('whoWheAreSection', JSON.stringify(data));
    dispatcher({ type: 'SUCCESS', payload: { data: data } });
}

// - Reducer
const whoWheAreSectionInitialState: WhoWheAreSectionPayload = {
    data: undefined,
    loading: false,
    errorMessage: '',
    isLoaded: false
}

const whoWheAreSectionData = localStorage.getItem('whoWheAreSection');
if (whoWheAreSectionData) {
    let localVersionData = localStorage.getItem('versions');
    if ( localVersionData) {
        const localData : VersionData = JSON.parse(localVersionData);
        if(localData.versions && localData.versions.whoWeAre ){
            const whoWheAreSectionDataObj: WhoWheAreSection = JSON.parse(whoWheAreSectionData);
            if (whoWheAreSectionDataObj.version === localData.versions.whoWeAre) {
                console.log('---> OK');
                whoWheAreSectionInitialState.data = whoWheAreSectionDataObj;
                whoWheAreSectionInitialState.isLoaded = true;
            }
        }
    }
}

let whoWheAreSectionReducer = (state: WhoWheAreSectionPayload, action: WhoWheAreSectionDispatcher): WhoWheAreSectionPayload => {
    switch (action.type) {
        case 'SUCCESS':
            return { ...state, loading: false, data: action.payload?.data, isLoaded: true };
        case 'ERROR':
            return { ...state, loading: false, errorMessage: action.payload?.errorMessage };
        default:
            return state;
    }
}

// Provider


const WhoWheAreSectionStateContext = React.createContext<WhoWheAreSectionPayload>({})
const WhoWheAreSectionDispatchContext = React.createContext< React.Dispatch<WhoWheAreSectionDispatcher> >(() => null)

export function useWhoWheAreSectionState(){
    const context = React.useContext(WhoWheAreSectionStateContext)
    if (context === undefined) {
        throw new Error("useAppsState must be used within a DiazarSuiteProvider");
    }

    return context;
}

export function useWhoWheAreSectionDispatch(){
    const context = React.useContext(WhoWheAreSectionDispatchContext)
    if (context === undefined) {
        throw new Error("useAppsDispatch must be used within a DiazarSuiteProvider");
    }

    return context;
}

interface ViewProps {
    children?: React.ReactNode | undefined
}

export const WhoWheAreSectionProvider: React.FunctionComponent<ViewProps> = (props) => {
    const { children } = props;
    const [state, dispatch] = React.useReducer(whoWheAreSectionReducer, whoWheAreSectionInitialState);

    return (
        <WhoWheAreSectionStateContext.Provider value={state}>
            <WhoWheAreSectionDispatchContext.Provider value={dispatch}>
                { children }
            </WhoWheAreSectionDispatchContext.Provider>
        </WhoWheAreSectionStateContext.Provider>
    )
}