import React from "react";

import {Parallax} from "react-parallax";
import {AnimateBox} from "./GenericComponents/AnimateBox";

interface ViewProps {
    children: string | React.ReactNode,
    description?: string | undefined
}


export const Header: React.FunctionComponent<ViewProps> = (props) => {
    const children = React.useMemo(() => {
        if (typeof props.children === 'string') {
            return (
                <React.Fragment>
                    <h1 className="mb30">{props.children} </h1>
                    { props.description && <p>{props.description}</p> }
                </React.Fragment>
            )
        }

        return props.children
    }, [props.children, props.description]);

    return (
        <Parallax bgImage="/page_react/images/assets/img_bg_3.jpg" strength={1000}>
            <header id="fh5co-header" className="fh5co-cover" role="banner">
                <div id="home" className="overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-xs-8 col-sm-6 col-md-6 text-left">
                            <div className="display-t">
                                <AnimateBox className="display-tc animate-box">{children}</AnimateBox>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </Parallax>
    );
}