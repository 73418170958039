const ROOT_URL = '/alx-admin/files/json';

export const ServicesUrl = {
    versions: `${ROOT_URL}/versions.json`,
    whoWeAre: `${ROOT_URL}/whoWeAre.json`,
    partners: `${ROOT_URL}/partners.json`,
    news: `${ROOT_URL}/news.json`,
    news2: `${ROOT_URL}/news2.json`,
    casinos: `${ROOT_URL}/casinos.json`,
    magazine: `${ROOT_URL}/magazine.json`,
    commissions: `${ROOT_URL}/commissions.json`,
}