import {VersionData} from "./ApiVersionProvider";
import React from "react";
import {CommissionsSection} from "../Actions/CommissionsAction";

interface CommissionsSectionDispatcher {
    type?: "SUCCESS" | "ERROR",
    payload?: CommissionsSectionPayload
}

interface CommissionsSectionPayload {
    data?: CommissionsSection,
    loading?: boolean,
    errorMessage?: string,
    isLoaded?: boolean
}

// - Actions
export async function setCommissionsSectionData( data: CommissionsSection, dispatcher: React.Dispatch<CommissionsSectionDispatcher>) {
    localStorage.setItem('commissionsSection', JSON.stringify(data));
    dispatcher({ type: 'SUCCESS', payload: { data: data } });
}

const commissionsSectionInitialState: CommissionsSectionPayload = {
    data: undefined,
    loading: false,
    errorMessage: '',
    isLoaded: false
}

const commissionsSectionData = localStorage.getItem('commissionsSection');
if (commissionsSectionData) {
    let localVersionData = localStorage.getItem('versions');
    if ( localVersionData) {
        const localData : VersionData = JSON.parse(localVersionData);
        if(localData.versions && localData.versions.commissions) {
            const commisionsSectionDataDataObj: CommissionsSection = JSON.parse(commissionsSectionData);
            if (commisionsSectionDataDataObj.version === localData.versions.news) {
                console.log('---> OK');
                commissionsSectionInitialState.data = commisionsSectionDataDataObj;
                commissionsSectionInitialState.isLoaded = true;
            }
        }
    }
}

let newsSectionReducer = (state: CommissionsSectionPayload, action: CommissionsSectionDispatcher): CommissionsSectionPayload => {
    switch (action.type) {
        case 'SUCCESS':
            return { ...state, loading: false, data: action.payload?.data, isLoaded: true };
        case 'ERROR':
            return { ...state, loading: false, errorMessage: action.payload?.errorMessage };
        default:
            return state;
    }
}


const CommissionsSectionStateContext = React.createContext<CommissionsSectionPayload>({})
const CommissionsSectionDispatchContext = React.createContext< React.Dispatch<CommissionsSectionDispatcher> >(() => null)

export function useCommissionsSectionState(){
    const context = React.useContext(CommissionsSectionStateContext)
    if (context === undefined) {
        throw new Error("useAppsState must be used within a DiazarSuiteProvider");
    }

    return context;
}

export function useCommissionsSectionDispatch(){
    const context = React.useContext(CommissionsSectionDispatchContext)
    if (context === undefined) {
        throw new Error("useAppsDispatch must be used within a DiazarSuiteProvider");
    }

    return context;
}


interface ViewProps {
    children?: React.ReactNode | undefined
}

export const CommissionsSectionProvider: React.FunctionComponent<ViewProps> = (props) => {
    const { children } = props;
    const [state, dispatch] = React.useReducer(newsSectionReducer, commissionsSectionInitialState);

    return (
        <CommissionsSectionStateContext.Provider value={state}>
            <CommissionsSectionDispatchContext.Provider value={dispatch}>
                { children }
            </CommissionsSectionDispatchContext.Provider>
        </CommissionsSectionStateContext.Provider>
    )
}
