import React from "react";
import {Link} from "react-router-dom";
import {LinkNav, NavbarLinks} from "./Navbar";

export const Footer: React.FunctionComponent = () => {
    const links : LinkNav[] = NavbarLinks

    return (
        <React.Fragment>
            <footer id="fh5co-footer" role="contentinfo">
                <div className="container">
                    <div className="row row-pb-md">

                        <div className="col-md-6 ">
                            <ul className="fh5co-footer-links">
                                <li><strong>Mapa del Sitio:</strong></li>
                                {
                                    links.map(link => (
                                        <li key={link.id}>
                                            <Link to={link.href}
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
                                            >
                                                {link.name}
                                                {link.children && link.children.length > 0 && (
                                                    <ul>
                                                        {
                                                            link.children.map(child => (
                                                                <li key={child.id}>
                                                                    <Link to={child.href}
                                                                          onClick={() => window.scrollTo({
                                                                              top: 0,
                                                                              behavior: 'smooth'
                                                                          })}
                                                                    >
                                                                        {child.name}
                                                                    </Link>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                )}
                                            </Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>


                        <div className="col-md-6 fh5co-widget">
                            <h3>Aieja.</h3>
                            <p>
                                La industria del entretenimiento y el juego en nuestro país, es incipiente y requiere de
                                certidumbre para convertirse en un ramo que atraiga mayor inversión, tanto nacional como
                                extranjera y con ello multiplicar el número de fuentes de empleo y la contribución
                                fiscal.
                            </p>
                            <p>
                                Esto sólo puede ser posible con una asociación que agrupe a los empresarios de este
                                medio.
                            </p>
                            <p>
                                <Link to='/'
                                      onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
                                >
                                    Ver más
                                </Link>
                            </p>

                        </div>

                    </div>

                    <div className="row copyright">
                        <div className="col-md-12 text-center">
                            <p>
                                <ul className="fh5co-footer-links inline-links">
                                    <li><strong>Marco Legal</strong>:</li>

                                    <li>
                                        <a href="http://www.dof.gob.mx"
                                           target="_blank"
                                           rel="noreferrer"
                                        >
                                            Diario Oficial de la Federación
                                        </a>
                                    </li>

                                    <li>
                                        <a href="http://www.juegosysorteos.gob.mx"
                                           target="_blank"
                                           rel="noreferrer"
                                        >
                                            Juegos y Sorteos
                                        </a>
                                    </li>
                                </ul>
                            </p>
                            <p>
                                <small className="block">&copy; 2024 AIEJA. All Rights Reserved.</small>
                            </p>
                            <p>
                                <ul className="fh5co-social-icons">
                                    <li>
                                        <a href="https://www.instagram.com/aiejamx/"
                                           target="_blank"
                                           rel="noreferrer"
                                        >
                                            <i className="icon-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/in/aieja-asociaci%C3%B3n-de-la-industria-del-juego-en-m%C3%A9xico-a-c-3314992a7/"
                                           target="_blank"
                                           rel="noreferrer"
                                        >
                                            <i className="icon-linkedin"></i>
                                        </a>
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>

                </div>
            </footer>
        </React.Fragment>
    );
}