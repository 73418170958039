import React from "react";
import {LinkNav} from "../Navbar";
import {DropdownMenu} from "./DropdownMenu";

interface ViewProps {
    link: LinkNav,
    onClick: (e: React.MouseEvent<HTMLAnchorElement>, to: string) => void
    isOffcanvas?: boolean,
}

export const ItemMenu: React.FunctionComponent<ViewProps> = (props) => {
    const {link, isOffcanvas, onClick} = props;
    const hasChildren = React.useMemo(() => link.children && link.children.length > 0, [link.children]);

    if (hasChildren) {
        return (<DropdownMenu link={link} isOffcanvas={isOffcanvas} onClick={onClick}/>)
    }
    return (
        <li key={link.id}>
            <a href={link.href} onClick={ e => onClick(e, link.href) }>
                {link.name}
            </a>
        </li>
    )
}