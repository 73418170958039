import React from "react";
import {MagazineData} from "../../Actions/MagazineActions";
import {AnimateBox} from "../../Components/GenericComponents/AnimateBox";

interface ViewProps {
    item: MagazineData,
    isSelected: boolean,
    setIsSelected?: React.Dispatch<React.SetStateAction<MagazineData>>
}

export const MagazineCard: React.FunctionComponent<ViewProps> = (props) => {
    const {item, isSelected, setIsSelected} = props;

    const scrollTo = React.useCallback((selectorId: string) => {
        setTimeout(() => {
            const element = document.getElementById(selectorId);
            if (element) {
                element.scrollIntoView({behavior: 'smooth'});
            }
        }, 50);
    }, []);

    const handleSelect = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        if (setIsSelected) setIsSelected(item);
        scrollTo('fh5co-blog');
    }, [item, scrollTo, setIsSelected])

    return (
        <AnimateBox className={`col-sm-4 col-md-3 magazine-item animate-box${isSelected ? ' active' : ''}`}
                    data-animate-effect="fadeInUp">
            <div className="fh5co-post" onClick={ handleSelect }>
                <p className="card-title">{item.name}</p>
                <div className={'cover-img'}>
                    <img src={`${item.cover}`} className="cover-img img-responsive"
                         alt={`AIEJA MAGAZINE ${item.name}`}/>
                </div>
            </div>
        </AnimateBox>
    )
}