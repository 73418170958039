import React from "react";
import {HealingMexico} from "./HealingMexico/HealingMexico";
import {useLocation} from "react-router-dom";
import {Commissions} from "./Commissions/Commissions";
import {Blog} from "../News/Blog";
import {CasinoMap} from "./CasinoMap/CasinoMap";

export const ActivitiesPage: React.FunctionComponent = () => {

    const location = useLocation()

    const scrollTo = React.useCallback((selectorId: string) => {
        setTimeout(() => {
            const element = document.getElementById(selectorId);
            if (element) {
                element.scrollIntoView({behavior: 'smooth'});
            }
        }, 50);
    }, []);

    React.useEffect(() => {
        if (location.pathname === '/actividades') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }else if(location.pathname === '/actividades/responsabilidad-social'){
            scrollTo('fh5co-testimonial');
        }else if(location.pathname === '/actividades/comisiones'){
            scrollTo('fh5co-counter');
        }else if(location.pathname === '/actividades/notas-aieja'){
            scrollTo('fh5co-blog');
        }else if(location.pathname === '/actividades/casinos'){
            scrollTo('fh5co-casino');
        }
    }, [location.pathname, scrollTo]);

    return (
        <React.Fragment>
            <HealingMexico />
            <Commissions />
            <Blog />
            <CasinoMap />
        </React.Fragment>
    );
}