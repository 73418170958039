import React from "react";
import {AnimateBox} from "../../../Components/GenericComponents/AnimateBox";
import {Skeleton} from "../../../Components/Skeleton";

export const CommissionsSkeleton : React.FunctionComponent = () => {
    const Items = React.useMemo( () => {
        const ret = [];

        for( let i = 0; i < 3; i++ ){
            ret.push( <AnimateBox key={`comision-${i}`} className="col-sm-6 col-md-4 animate-box" data-animate-effect="fadeInLeft">
                <div className="feature-center counter_text">
                    <span className="counter">
                        <Skeleton type={"text_title"} />
                    </span>
                    <span className="counter-label">
                        <Skeleton type={"text_title"} />
                    </span>
                </div>
            </AnimateBox> )
        }

        return ret;
    } , [] );

    return (
        <div id="fh5co-counter">
            <div className="container">

                <AnimateBox className="row animate-box row-pb-md" data-animate-effect="fadeInUp">
                    <div className="col-md-8 col-md-offset-2 text-left fh5co-heading">
                        <Skeleton type={"text_title"} />
                    </div>
                </AnimateBox>

                <div className="row flex-row">
                    { Items }
                </div>
            </div>
        </div>
    )
}