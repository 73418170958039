import React, {CSSProperties} from "react";
import {LinkNav} from "../Navbar";
import {ItemMenu} from "./ItemMenu";

interface ViewProps {
    link: LinkNav,
    onClick: (e: React.MouseEvent<HTMLAnchorElement>, to: string) => void
    isOffcanvas?: boolean,
}

export const DropdownMenu: React.FunctionComponent<ViewProps> = (props) => {
    const {link, isOffcanvas, onClick} = props;
    const [isClick, setIsClick] = React.useState(false);
    const [isEnter, setisEnter] = React.useState(false);

    const isOpen = React.useMemo(() => {
        return isClick || isEnter;
    }, [isClick, isEnter])

    const clickHandle = React.useCallback(() => {
        setIsClick(prev => !prev);
    }, []);

    const enterHandle = React.useCallback(() => {
        setisEnter(true);
    }, []);

    const exitHandle = React.useCallback(() => {
        setisEnter(false);
        setIsClick(false);
    }, []);

    const items = React.useMemo(() => {
        if (link.children === undefined || link.children.length === 0) {
            return null;
        }

        return link.children.map((item, index) => <ItemMenu key={index} link={item} isOffcanvas={isOffcanvas} onClick={onClick}/>)
    }, [link.children, isOffcanvas, onClick]);

    const liClass = React.useMemo(() => {
        if (isOffcanvas) {
            return `offcanvas-has-dropdown`;
        }

        return `has-dropdown`;
    }, [isOffcanvas]);

    const styleProps: CSSProperties = React.useMemo(() => {
        if (isOpen) {
            return {display: "block", visibility: "visible"};
        }

        return {display: "none", visibility: "hidden"};
    }, [isOpen])

    return (
        <li className={liClass} onClick={clickHandle} onMouseEnter={enterHandle} onMouseLeave={exitHandle}>
            <a href={link.href} onClick={e => {
                onClick(e, link.href);
            }}>
                {link.name}
            </a>
            <ul className={`dropdown ${isOpen ? 'animated-fast fadeInUpMenu' : ''}`} style={styleProps}>
                {items}
            </ul>
        </li>
    );
}