// OffcanvasMenu.tsx
import React, {useState, useEffect} from 'react';
import {LinkNav, NavbarLinks} from "../Navbar";
import {ItemMenu} from "./ItemMenu";

interface ViewProps {
    hasClass?: boolean,
    onClick: (e: React.MouseEvent<HTMLAnchorElement>, to: string) => void
}

export const OffcanvasMenu: React.FunctionComponent<ViewProps> = (props) => {
    const {hasClass, onClick} = props
    const [isOpen, setIsOpen] = useState(false);
    const links : LinkNav[] = NavbarLinks

    // Función para alternar el estado del menú
    const toggleMenu = React.useCallback((e: any) => {
        e.preventDefault();
        setIsOpen(prev => !prev);
    }, [])

    // Cierra el menú si se hace clic fuera de él
    const handleClickOutside = React.useCallback((event: MouseEvent) => {
        const target = event.target as HTMLElement;
        if (
            !target.closest('#fh5co-offcanvas') && // Si el clic no es dentro del offcanvas
            !target.closest('.js-fh5co-nav-toggle') // Y tampoco es en el botón de toggle
        ) {
            setIsOpen(false); // Cierra el menú
        }
    }, [])

    useEffect(() => {
        // Añade el evento de clic al documento cuando el menú está abierto
        if (isOpen) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }

        // Limpia el evento al desmontar
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside, isOpen]);

    const linksNav = React.useMemo(() => {
        return links?.map((link) => {
            return <ItemMenu isOffcanvas={true} key={link.id} link={link} onClick={onClick}/>
        })

    }, [links, onClick])

    const SandwichButtom = React.useMemo(() => {
        return (
            <a
                href={`#anchor`}
                onClick={toggleMenu}
                className={`js-fh5co-nav-toggle fh5co-nav-toggle ${isOpen ? 'active' : ''} ${!hasClass || isOpen ? 'fh5co-nav-white' : ''}`}
            >
                <i></i>
            </a>
        )
    }, [hasClass, isOpen, toggleMenu])

    return (
        <React.Fragment>
            {SandwichButtom}

            {/* Menú offcanvas */}
            <div id="fh5co-offcanvas" className={isOpen ? 'open' : ''}>
                <ul>
                    {linksNav}
                </ul>
            </div>
        </React.Fragment>
    );
};
