import React from "react";
import {AnimateBox} from "../../Components/GenericComponents/AnimateBox";
import {Skeleton} from "../../Components/Skeleton";

export const PartnerSkeleton: React.FunctionComponent = () => {

    return (
        <section>
            <div id="fh5co-project">
                <div className="container">
                    <div className="row row-pb-md">
                        <AnimateBox className="col-md-8 col-md-offset-2 text-left fh5co-heading  animate-box">
                            <Skeleton type="text_title" />
                        </AnimateBox>
                    </div>

                    <div className="row">
                        <AnimateBox className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                            <div className="square">
                                <Skeleton type="square" />
                            </div>
                        </AnimateBox>

                        <AnimateBox className="hidden-xs col-sm-6 col-md-4 col-lg-3">
                            <div className="square">
                                <Skeleton type="square"/>
                            </div>
                        </AnimateBox>

                        <AnimateBox className="hidden-xs hidden-sm col-md-4 col-lg-3">
                            <div className="square">
                                <Skeleton type="square"/>
                            </div>
                        </AnimateBox>

                        <AnimateBox className="hidden-xs hidden-sm hidden-md col-lg-3">
                            <div className="square">
                                <Skeleton type="square"/>
                            </div>
                        </AnimateBox>
                    </div>
                </div>

            </div>
        </section>
    );
}