import React from "react";
import {DefaultBannerTitle} from "../DefaultBannerTitle";

export const BannerHome: React.FunctionComponent = () => {
    return (
        <DefaultBannerTitle
            title={"Creemos en el poder del entretenimiento " +
                "responsable como una vía para construir " +
                "experiencias memorables y fomentar el desarrollo económico del país."}
            subTitle={""}
            description={""}
            isMinimal={true}
        />
    )
}