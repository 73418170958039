import React from "react";
import {AnimateBox} from "../../Components/GenericComponents/AnimateBox";
import {Skeleton} from "../../Components/Skeleton";

export const BlogSkeleton: React.FunctionComponent = () => {
    return (
        <div id="fh5co-blog" className="fh5co-bg-section">
            <div className="container">
                <AnimateBox className="row animate-box row-pb-md" data-animate-effect="fadeInUp">
                    <div className="col-md-8 col-md-offset-2 text-left fh5co-heading">
                        <Skeleton type={"text_title"} />
                    </div>
                </AnimateBox>
                <div className="row flex-row">
                    <AnimateBox className="col-xs-12 col-sm-6 col-md-4">
                        <Skeleton />
                    </AnimateBox>
                    <AnimateBox className="hidden-xs col-sm-6 col-md-4">
                        <Skeleton />
                    </AnimateBox>
                    <AnimateBox className="hidden-xs hidden-sm col-sm-6 col-md-4">
                        <Skeleton />
                    </AnimateBox>
                </div>
            </div>
        </div>
    )
};