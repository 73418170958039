import React from "react";
import {routes, RoutesType} from "../Configs/routes";
import {useLocation} from "react-router-dom";

import {Header} from "../Components/Header";
import {Navbar} from "../Components/Navbar";
import {getVersionData, useVersionDispatch, useVersionState} from "../Contexts/ApiVersionProvider";
import {Footer} from "../Components/Footer";

interface ViewProps {
    children:  React.ReactNode;
}

function comparePaths(path1: string, path2: string): boolean {
    // Convertir el path dinámico en una expresión regular
    const regexPattern = '^' +
        path2
            .replace(/:[^/]+/g, '[^/]+') // Reemplaza parámetros como :id con [^/]+ para coincidir con cualquier valor sin incluir /
            .replace(/\*/g, '.*') + '$'; // Reemplaza * con .* para que coincida con cualquier secuencia de caracteres

    const regex = new RegExp(regexPattern);

    // console.log('Comparando:', path1, path2, 'Patrón regex:', regexPattern, 'Resultado:', regex.test(path1));
    return regex.test(path1);
}

export const MainLayout: React.FunctionComponent<ViewProps> = (props) => {
    const [title, setTitle] = React.useState<React.ReactNode>();
    const [description, setDescription] = React.useState<string>();

    const {children} = props;

    const location = useLocation()

    React.useEffect(() => {
        let route: RoutesType | undefined = undefined

        for (let i = 0; i < routes.length; i++) {
            if (comparePaths(location.pathname, routes[i].path)) {
                route = routes[i]
                break
            }

            if ( routes[i].children ) {
                const routeChildren = routes[i].children

                if (routeChildren !== undefined) {
                    for (let j = 0; j < routeChildren.length; j++) {
                        if (comparePaths(location.pathname,  routes[i].path + '/' +routeChildren[j].path)) {
                            route = routeChildren[j]
                            break
                        }
                    }
                }

                if (route) {
                    break
                }
            }
        }

        if (route) {
            setTitle(route.title)
            setDescription(route.description)
        }

    }, [location.pathname]);


    const versionDispatch = useVersionDispatch();
    const version = useVersionState()

    React.useEffect(() => {
        if (!version.isLoaded) {
            getVersionData(versionDispatch).then( respose => {} )
        }
    }, [version.isLoaded, versionDispatch])

    return (
        <React.Fragment>
            <Navbar />
            <Header description={description}>{ title }</Header>
            { children }
            <Footer/>
        </React.Fragment>
    )
}


