import React from "react";
import {Skeleton} from "../../../Components/Skeleton";
import {AnimateBox} from "../../../Components/GenericComponents/AnimateBox";

export const CasinosSkeleton: React.FunctionComponent = () => {
    return (
        <div id="fh5co-casino">
            <div className="container">
                <AnimateBox className="row animate-box row-pb-md" data-animate-effect="fadeInUp">
                    <div className="col-md-8 col-md-offset-2 text-left fh5co-heading">
                        <Skeleton type={"text_title"}/>
                    </div>
                </AnimateBox>

                <div className="row">
                    <Skeleton type={"square"}/>
                    <Skeleton type={"square"}/>
                    <Skeleton type={"square"}/>
                </div>
            </div>
        </div>
    )
}