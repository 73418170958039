import React from 'react';

import {
    BrowserRouter as Router,
    Routes, Route, Navigate
} from 'react-router-dom';
import { ClientContextProvider } from 'react-fetching-library';
import {Client} from "./Components/api/Client";
import {routes} from "./Configs/routes";
import {MainLayout} from "./Layouts/MainLayout";
import {VersionProvider} from "./Contexts/ApiVersionProvider";

import Moment from "react-moment";
import 'moment/locale/es';
import {WhoWheAreSectionProvider} from "./Contexts/WhoWheAreSectionProvider";
import {PartnerSectionProvider} from "./Contexts/PartnersSectionProvider";
import {NewsSectionProvider} from "./Contexts/NewsSectionProvider";
import {CasinosSectionProvider} from "./Contexts/CasinosProvider";
import {MagazineSectionProvider} from "./Contexts/MagazineSectionProvider";
import {CommissionsSectionProvider} from "./Contexts/CommissionsProvider";

Moment.globalLocale = 'es';

const AppRoutes:React.FunctionComponent = (_props) => {

    return (
        <Router>
            <MainLayout >
                <Routes>
                    { routes.map( (props ) =>
                        <Route
                            key={props.path}
                            path={props.path}
                            element={( !props.isPrivate ) ? <props.component /> : <Navigate to="/" replace />}
                        >
                            {props.children ? props.children.map(( el ) => {
                                return(
                                    <Route
                                        key={el.path}
                                        path={el.path}
                                        element={(!el.isPrivate  ) ? <el.component /> : <Navigate to="/login" replace />} />
                                )
                            }) : <></> }
                        </Route>
                    ) }
                </Routes>
            </MainLayout>
        </Router>
    )
}

function App() {
  global.Buffer = global.Buffer || require('buffer').Buffer;
  return (
      <ClientContextProvider client={Client}>
          <VersionProvider>
              <WhoWheAreSectionProvider>
                  <PartnerSectionProvider>
                      <NewsSectionProvider>
                          <CasinosSectionProvider>
                              <MagazineSectionProvider>
                                  <CommissionsSectionProvider>
                                    <AppRoutes />
                                  </CommissionsSectionProvider>
                              </MagazineSectionProvider>
                          </CasinosSectionProvider>
                      </NewsSectionProvider>
                  </PartnerSectionProvider>
              </WhoWheAreSectionProvider>
          </VersionProvider>
      </ClientContextProvider>
  );
}

export default App;
