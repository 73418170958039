import React from "react";
import {NewsData} from "../../Actions/NewsAction";
import {AnimateBox} from "../../Components/GenericComponents/AnimateBox";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import {Rect} from "react-content-loader/native";

interface NewsCardProps{
    news: NewsData;
    isHome: boolean;
}
export const NewsCard: React.FunctionComponent<NewsCardProps> = (props) => {
    const {news, isHome} = props;

    const AUTHOR = React.useMemo(() => {
        if (news.fuente === null) {
            return null;
        }

        return (
            <React.Fragment>
                <strong>Fuente: </strong> { news.fuente }
            </React.Fragment>
        );
    }, [news.fuente])

    const LinkData = React.useMemo( ()=>{
        if (news.link === null){
            return (
                <Link to={ `/noticias/ver-todas/${news.id}` }
                      onClick={ (isHome) ? () => window.scrollTo({ top: 0, behavior: 'smooth' }) : undefined }
                >
                    {news.title}
                </Link>
            )
        }

        return (
            <a href={news.link} referrerPolicy="no-referrer" target="_blank" rel="noreferrer" >{news.title}</a>
        );
    }, [isHome, news.id, news.link, news.title] )

    return (
        <AnimateBox className="col-md-4 col-sm-4 animate-box" data-animate-effect="fadeInUp">
            <div className="fh5co-post">
                <Moment format="MMM (YYYY). DD" className="fh5co-date">{news.newsDate}</Moment>
                <h3> {LinkData} </h3>
                <p>{news.description}</p>
                <p className="author">
                    <cite>{AUTHOR}</cite>
                </p>
            </div>
        </AnimateBox>
    )
}