import React from "react";

export const NotFound: React.FunctionComponent = (_props) => {
    return (
        <React.Fragment>
            <div>
                <h1 style={{textAlign:'center', padding:'40px', fontSize:'4em'}}>404</h1>
            </div>
        </React.Fragment>
    )
}