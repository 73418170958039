import React from "react";
import {MagazineData} from "../../Actions/MagazineActions";
import {useNavigate} from "react-router-dom";
import PDFFlipbook from "./PDFFlipbook";

interface ViewProps {
    item?: MagazineData
    openModal: boolean
}

export const MagazineVisor: React.FunctionComponent<ViewProps> = (props) => {
    const {item, openModal} = props;
    const navigate = useNavigate();

    const closeModal = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate('/revista');
    }, [navigate])

    const title = React.useMemo(() => {
        return 'AIEJA Magazine ' + item?.name
    }, [item?.name])

    const PDF_FLIP = React.useMemo(()=>{
        console.log (item?.pdf)
        if (item?.pdf){
            return <PDFFlipbook pdfUrl={item.pdf} />
        }
         return null;
    }, [item?.pdf] )

    if (item === undefined || !openModal) return null

    return (
        <div id="info-modal" className={`modal fade${openModal ? ' in' : ''}`} tabIndex={-1} role="dialog"
             style={openModal ? {display: "block"} : undefined}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={closeModal}><span
                            aria-hidden="true">&times;</span></button>
                        <h4 className="modal-title">{title}</h4>
                    </div>
                    <div className="modal-body">
                        {PDF_FLIP}
                    </div>
                </div>
            </div>

        </div>
    )
}