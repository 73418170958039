import React from "react";
import ContentLoader from "react-content-loader";

interface ViewProps {
    type?: "default" | "text" | "text_title" | "square"
}

export const Skeleton: React.FunctionComponent<ViewProps> = (props) => {
    const {type} = props;

    const CONTENT = React.useMemo( () => {
        let data = (<React.Fragment />);

        switch (type) {
            case "text":
                data = (
                    <React.Fragment>
                        <rect x="0" y="56" rx="3" ry="3" width="410" height="6"/>
                        <rect x="0" y="72" rx="3" ry="3" width="380" height="6"/>
                        <rect x="0" y="88" rx="3" ry="3" width="178" height="6"/>
                    </React.Fragment>
                )
                break

            case "text_title":
                data = (
                    <React.Fragment>
                        <rect x="0" y="45" rx="3" ry="3" width="100" height="2"/>
                        <rect x="0" y="88" rx="3" ry="3" width="178" height="4"/>
                        <rect x="0" y="72" rx="3" ry="3" width="380" height="6"/>
                    </React.Fragment>
                )
                break;

            case "square":
                data = (
                    <React.Fragment>
                        <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%"/>
                    </React.Fragment>
                )
                break;

            case "default":
            default:
                data = (
                    <React.Fragment>
                    <rect x="48" y="8" rx="3" ry="3" width="88" height="6"/>
                        <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
                        <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
                        <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
                        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
                        <circle cx="20" cy="20" r="20" />
                    </React.Fragment>
                )
                break;
        }

        return data;
    }, [type] )

    return (
        <ContentLoader
            speed={2}
            width={'100%'}
            height={ (type && type === 'square') ? '100%' : 200}
            viewBox={ (type && type === 'square') ? undefined : "0 0 400 160"}
            backgroundColor="rgba(42, 54, 59, 0.2)"
            foregroundColor="#F73859"
        >
            { CONTENT }
        </ContentLoader>
    )
}