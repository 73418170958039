import {CasinosSection} from "../Actions/CasinosActions";
import React from "react";
import {VersionData} from "./ApiVersionProvider";

interface CasinosSectionDispatcher {
    type?: "SUCCESS" | "ERROR",
    payload?: CasinosSectionPayload
}

interface CasinosSectionPayload {
    data?: CasinosSection,
    loading?: boolean,
    errorMessage?: string,
    isLoaded?: boolean
}

// - Actions
export async function setCasinosSectionData( data: CasinosSection, dispatcher: React.Dispatch<CasinosSectionDispatcher>) {
    localStorage.setItem('casinosSection', JSON.stringify(data));
    dispatcher({ type: 'SUCCESS', payload: { data: data } });
}

const initialState: CasinosSectionPayload = {
    data: undefined,
    loading: false,
    errorMessage: '',
    isLoaded: false
}

const sectionData = localStorage.getItem('casinosSection');
if (sectionData) {
    let localVersionData = localStorage.getItem('versions');
    if ( localVersionData) {
        const localData : VersionData = JSON.parse(localVersionData);
        if(localData.versions && localData.versions.casinos ){
            const sectionDataDataObj: CasinosSection = JSON.parse(sectionData);
            if (sectionDataDataObj.version === localData.versions.casinos) {
                initialState.data = sectionDataDataObj;
                initialState.isLoaded = true;
            }
        }
    }
}

let sectionReducer = (state: CasinosSectionPayload, action: CasinosSectionDispatcher): CasinosSectionPayload => {
    switch (action.type) {
        case 'SUCCESS':
            return { ...state, loading: false, data: action.payload?.data, isLoaded: true };
        case 'ERROR':
            return { ...state, loading: false, errorMessage: action.payload?.errorMessage };
        default:
            return state;
    }
}


const CasinosSectionStateContext = React.createContext<CasinosSectionPayload>({})
const CasinosSectionDispatchContext = React.createContext< React.Dispatch<CasinosSectionDispatcher> >(() => null)

export function useCasinosSectionState(){
    const context = React.useContext(CasinosSectionStateContext)
    if (context === undefined) {
        throw new Error("useAppsState must be used within a DiazarSuiteProvider");
    }

    return context;
}

export function useCasinosSectionDispatch(){
    const context = React.useContext(CasinosSectionDispatchContext)
    if (context === undefined) {
        throw new Error("useAppsDispatch must be used within a DiazarSuiteProvider");
    }

    return context;
}

interface ViewProps {
    children?: React.ReactNode | undefined
}

export const CasinosSectionProvider: React.FunctionComponent<ViewProps> = (props) => {
    const { children } = props;
    const [state, dispatch] = React.useReducer(sectionReducer, initialState);

    return (
        <CasinosSectionStateContext.Provider value={state}>
            <CasinosSectionDispatchContext.Provider value={dispatch}>
                { children }
            </CasinosSectionDispatchContext.Provider>
        </CasinosSectionStateContext.Provider>
    )
}

