import React from "react";
import {WhoWeAre} from "./WhoWheAre/WhoWeAre";
import {Partners} from "../Partners/Partners";
import {Blog} from "../News/Blog";
import {useLocation} from "react-router-dom";

const Home: React.FunctionComponent = (_props) => {
    const [isOpenModalWhoWeAre, setIsOpenModalWhoWeAre] = React.useState<boolean>(false);
    const [tagModalWhoWeAre, setTagModalWhoWeAre] = React.useState<string>('');

    const location = useLocation()

    const scrollTo = React.useCallback((selectorId: string) => {
        setTimeout(() => {
            const element = document.getElementById(selectorId);
            if (element) {
                    element.scrollIntoView({behavior: 'smooth'});
            }
        }, 50);
    }, []);

    React.useEffect(() => {
        if (location.pathname === '/') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }else if (location.pathname.startsWith('/quienes-somos')) {
            let element = 'fh5co-who-we-are';
            setTagModalWhoWeAre('');

            switch (location.pathname) {
                case '/quienes-somos/mision':
                case '/quienes-somos/mision/ver-mas':
                    element = 'fh5co-who-we-are-mision';
                    setTagModalWhoWeAre('mision');
                    break;

                case '/quienes-somos/vision':
                case '/quienes-somos/vision/ver-mas':
                    element = 'fh5co-who-we-are-vision';
                    setTagModalWhoWeAre('vision');
                    break;

                case '/quienes-somos/nuestro-presidente':
                case '/quienes-somos/nuestro-presidente/ver-mas':
                    setTagModalWhoWeAre('nuestro-presidente');
                    element = 'fh5co-who-we-are-nuestro-presi';
                    break;
            }

            setIsOpenModalWhoWeAre( location.pathname.endsWith('/ver-mas') );
            scrollTo(element);
        }else if(location.pathname === '/socios'){
            scrollTo('fh5co-project');
        }else if(location.pathname === '/noticias'){
            scrollTo('fh5co-blog');
        }
    }, [location.pathname, scrollTo]);



   return (
      <React.Fragment>
          <WhoWeAre isOpenModal={isOpenModalWhoWeAre}
                    tagModal={tagModalWhoWeAre}
          />
          <Partners/>
          <Blog/>
      </React.Fragment>
   )
}

export default Home
