import React, { useEffect, useState } from 'react';
import { getDocument, GlobalWorkerOptions, PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';
import HTMLFlipBook from "react-pageflip";

// Configuración del trabajador de PDF.js
GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/page_react/js/pdf.worker.min.js`;

export const usePdfPages = (pdfUrl: string) => {
    const [pages, setPages] = useState<string[]>([]);
    const [progress, setProgress] = useState<number>(0); // Progreso de procesamiento de páginas
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const loadPDFPages = async () => {
            try {
                const pdf: PDFDocumentProxy = await getDocument(pdfUrl).promise;
                const pagesArray: string[] = [];
                const totalPages = pdf.numPages;

                for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
                    const page: PDFPageProxy = await pdf.getPage(pageNumber);
                    const viewport = page.getViewport({ scale: 2.0 });  // Ajusta el valor del scale aquí
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    if (!context) throw new Error("Contexto de canvas no disponible");

                    context.imageSmoothingEnabled = true;
                    canvas.width = viewport.width;
                    canvas.height = viewport.height;

                    await page.render({ canvasContext: context, viewport }).promise;
                    pagesArray.push(canvas.toDataURL());

                    setProgress(Math.floor((pageNumber / totalPages) * 100));
                }

                setPages(pagesArray);
            } catch (err: any) {
                setError(err.message);
            }
        };

        loadPDFPages();
    }, [pdfUrl]);

    return { pages, progress, error };
};

// PDFFlipbook.tsx

interface PDFFlipbookProps {
    pdfUrl: string;
}

const PDFFlipbook: React.FC<PDFFlipbookProps> = ({ pdfUrl }) => {
    const { pages, progress, error } = usePdfPages(pdfUrl);

    const LOADING = React.useMemo(() => {
        return (
            <div className="pdf-flipbook-loader">
                <p>Descargando y procesando el PDF...</p>
                <div className="progress-bar-container">
                    <div className="progress-bar" style={{ width: `${progress}%` }}></div>
                </div>
                <p>{progress}%</p>
            </div>
        );
    }, [progress]);

    const PDF_FLIP = React.useMemo(() => {
        if (progress < 100) return null;
        return (
            <HTMLFlipBook
                width={400} // Usa valores iniciales si es necesario
                height={600}
                minWidth={400}
                minHeight={600}
                maxWidth={10000}
                maxHeight={15000}
                className="flipbook"
                startPage={0}
                size="stretch" // Hace que el flipbook se ajuste al contenedor
                drawShadow={true}
                flippingTime={600}
                useMouseEvents={true}
                autoSize={true}
                maxShadowOpacity={0.5}
                showCover={true}
                mobileScrollSupport={true}
                clickEventForward={true}
                swipeDistance={30}
                showPageCorners={true}
                disableFlipByClick={false}
                style={{}}
                usePortrait={true}
                startZIndex={0}
            >
                {pages.map((page, index) => (
                    <div key={index} className="flipbook-page">
                        <img src={page} alt={`Página ${index + 1}`}/>
                    </div>
                ))}
            </HTMLFlipBook>
        );
    }, [pages, progress]);

    if (error) return <div>Error al cargar el PDF: {error}</div>;

    if (progress < 100) return LOADING;

    return (
        <div className="pdf-flipbook-container">
            {PDF_FLIP}
        </div>
    );
};

export default PDFFlipbook;

