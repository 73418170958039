import React from "react";
import {ServicesUrl} from "../Actions/paths";

export interface VersionData {
    versions: {
        whoWeAre?: string,
        partners?: string,
        news?: string,
        casinos?: string,
        magazine?: string,
        commissions?: string
    };
}

export interface VersionDispacher {
    type?:"REQUEST" | "SUCCESS" | "ERROR" |  "RESET",
    payload?: VersionPayload
}

export interface VersionPayload {
    data?:       VersionData,
    loading?:       boolean,
    errorMessage?:  string,
    isLoaded?: boolean
}

// - Actions
export async function getVersionData(dispatch:React.Dispatch<VersionDispacher>){
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try{
        dispatch( {type:'REQUEST'} )
        let response = await fetch(`${ServicesUrl.versions}?v${ new Date().getTime() }` , requestOptions)

        let data : VersionData = await response.json();
        localStorage.setItem('versions', JSON.stringify(data));

        dispatch({ type: 'SUCCESS', payload:{ data: data } });
    }catch (error) {
        let message = "Error al momento de realizar la petición"
        dispatch({ type: 'ERROR', payload:{ errorMessage: message } });
    }

    setTimeout(() => {
        dispatch({ type: 'RESET' });
    }, 300000 ) // 5 minutos
}



// - Reducer
const versionInitialState: VersionPayload = {
    data: { versions:{} },
    loading: false,
    errorMessage: '',
    isLoaded: false
}

// inicializamos el objeto mediante el localstorange
let localVerrsionData = localStorage.getItem('versions');
if ( localVerrsionData && versionInitialState.data) {
    const localData : VersionData = JSON.parse(localVerrsionData);
    if(localData.versions){
        if(localData.versions.whoWeAre){
            versionInitialState.data.versions.whoWeAre = localData.versions.whoWeAre;
        }

        if(localData.versions.partners){
            versionInitialState.data.versions.partners = localData.versions.partners;
        }

        if(localData.versions.news){
            versionInitialState.data.versions.news = localData.versions.news;
        }

        if(localData.versions.casinos){
            versionInitialState.data.versions.casinos = localData.versions.casinos;
        }

        if(localData.versions.magazine){
            versionInitialState.data.versions.magazine = localData.versions.magazine;
        }
    }
}

let versionReducer: React.Reducer<VersionPayload, VersionDispacher> ;
versionReducer = (state, action) : VersionPayload => {
    switch (action.type) {
        case 'REQUEST':
            return { ...state, loading: true, errorMessage: '' };
        case 'SUCCESS':
            return { ...state, loading: false, data: action.payload?.data, isLoaded: true };
        case 'ERROR':
            return { ...state, loading: false, errorMessage: action.payload?.errorMessage };
        case 'RESET':
            return versionInitialState;
        default:
            return state;
    }
}

// - Provider

const VersionStateContext = React.createContext<VersionPayload>({})
const VersionDispatchContext = React.createContext< React.Dispatch<VersionDispacher> >(() => null)


export function useVersionState(){
    const context = React.useContext(VersionStateContext)
    if (context === undefined) {
        throw new Error("useAppsState must be used within a DiazarSuiteProvider");
    }

    return context;
}

export function useVersionDispatch(){
    const context = React.useContext(VersionDispatchContext)
    if (context === undefined) {
        throw new Error("useAppsDispatch must be used within a DiazarSuiteProvider");
    }

    return context;
}

interface ViewProps {
    children?: React.ReactNode | undefined
}

export const VersionProvider: React.FunctionComponent<ViewProps> = (props) => {
    const { children } = props;
    const [state, dispatch] = React.useReducer(versionReducer, versionInitialState);

    return (
        <VersionStateContext.Provider value={state}>
            <VersionDispatchContext.Provider value={dispatch}>
                { children }
            </VersionDispatchContext.Provider>
        </VersionStateContext.Provider>
    )
}