import React from "react";
import {AnimateBox} from "../../../Components/GenericComponents/AnimateBox";
import {getCommissionsData} from "../../../Actions/CommissionsAction";
import {useParameterizedQuery} from "react-fetching-library";
import {useVersionState} from "../../../Contexts/ApiVersionProvider";
import {
    setCommissionsSectionData,
    useCommissionsSectionDispatch,
    useCommissionsSectionState
} from "../../../Contexts/CommissionsProvider";
import {CommissionsSkeleton} from "./CommissionsSkeleton";
import Moment from "react-moment";


export const Commissions: React.FunctionComponent = () => {
    const [isLoadData, setIsLoadData] = React.useState(false);

    const getCommissionsDataAction = useParameterizedQuery(getCommissionsData);
    const { data } = useVersionState()
    const version = React.useMemo( () => {
        return data?.versions?.commissions
    }, [data] );

    const commissionsSectionState = useCommissionsSectionState()
    const commissionsSectionDispatch = useCommissionsSectionDispatch()

    const commissionsSection = React.useMemo( () => {
        console.log(commissionsSectionState.data)
        return commissionsSectionState.data
    }, [commissionsSectionState.data]);

    const commissionsData = React.useMemo( ()=>{
        return commissionsSection ? commissionsSection.commissions : [];
    }, [commissionsSection] )

    const forceDownload = React.useMemo( () => {
        if (commissionsSection === undefined || version === undefined) {
            setIsLoadData(false);
            return true
        }

        if (commissionsSection.version !== version) {
            setIsLoadData(false);
            return true
        }

        setIsLoadData(true);
        return false
    }, [commissionsSection, version] );

    React.useEffect(() => {
        if ( (forceDownload && version) || (version && version.length > 0 && !commissionsSection && !isLoadData) ){
            setIsLoadData(true);

            if ( getCommissionsDataAction.loading ) return;

            getCommissionsDataAction.query(version).then(response => {
                if (response.payload) {
                    setCommissionsSectionData(response.payload, commissionsSectionDispatch).then();
                }
            });
        }
    }, [forceDownload, data, isLoadData, version, commissionsSection, getCommissionsDataAction, commissionsSectionDispatch]);

    if (commissionsSection === undefined || getCommissionsDataAction.loading) {
        return <CommissionsSkeleton />
    }

    return (
        <div id="fh5co-counter">
            <div className="container">

                <AnimateBox className="row animate-box" data-animate-effect="fadeInUp">
                    <div className="col-md-8 col-md-offset-2 text-left fh5co-heading">
                        <span>{commissionsSection.subtitle}</span>
                        <h2 style={{fontSize:'3em'}}>{commissionsSection.title}</h2>
                        <p>{commissionsSection.description}</p>
                    </div>
                </AnimateBox>

                <div className="row flex-row">
                    {
                        commissionsData.map( (commission, index) => (
                            <AnimateBox key={`comision-${index}`} className="col-sm-6 col-md-4 animate-box" data-animate-effect="fadeInLeft">
                                <div className="feature-center counter_text">
                                    <div className="counter">{commission.title}</div>
                                    <div className="counter-label">
                                        <dl>
                                            <dd>{commission.description}</dd>
                                        </dl>
                                        <div className={"col-sm-6"}>
                                            <dl>
                                                <dt>Vicepresidente</dt>
                                                <dd><small>{commission.vicepresident}</small></dd>
                                            </dl>
                                        </div>
                                        <div className={"col-sm-6"}>
                                            <dl>
                                                <dt>Aprobada</dt>
                                                <dd>
                                                    <small>
                                                        <Moment format="LL">{commission.date}</Moment>
                                                        <br/>
                                                        {commission.approved}
                                                    </small>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </AnimateBox>
                        ))
                    }

                </div>
            </div>
        </div>
    )
}