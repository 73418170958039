import React from "react";
import Home from "../Pages/Home/Home";
import {NotFound} from "../Pages/NotFound/NotFound";
import {BannerHome} from "../Pages/Home/BannerHome";
import {AllPartners} from "../Pages/Partners/AllPartners";
import {DefaultBannerTitle} from "../Pages/DefaultBannerTitle";
import {AllNews} from "../Pages/News/AllNews";
import {Contact} from "../Pages/Contact/Contact";
import {ActivitiesPage} from "../Pages/Activities/ActivitiesPage";
import {Magazine} from "../Pages/Magazine/Magazine";


export interface RoutesType {
    path: string;
    component: React.ComponentType;
    isPrivate: boolean;
    title: string | React.ReactNode;
    description?: string;
    isHome: boolean;
    children?: {
        path: string;
        component: React.ComponentType;
        isPrivate:boolean
        title: string | React.ReactNode;
        description?: string;
        isHome: boolean;
    }[];
}

export const routes:RoutesType[] = [

    {
        path:'/',
        component:Home,
        isPrivate: false,
        isHome: true,
        title: <BannerHome />
    },

    {
        path:'/quienes-somos',
        component:Home,
        isPrivate: false,
        isHome: true,
        title: <BannerHome />,
        children: [
            {
                path:'vision',
                component:Home,
                isPrivate: false,
                isHome: true,
                title: <BannerHome />,
            },
            {
                path:'vision/ver-mas',
                component:Home,
                isPrivate: false,
                isHome: true,
                title: <BannerHome />,
            },
            {
                path:'mision',
                component:Home,
                isPrivate: false,
                isHome: true,
                title: <BannerHome />,
            },
            {
                path:'mision/ver-mas',
                component:Home,
                isPrivate: false,
                isHome: true,
                title: <BannerHome />,
            },
            {
                path:'nuestro-presidente',
                component:Home,
                isPrivate: false,
                isHome: true,
                title: <BannerHome />,
            },
            {
                path:'nuestro-presidente/ver-mas',
                component:Home,
                isPrivate: false,
                isHome: true,
                title: <BannerHome />,
            }
        ]
    },
    {
        path:'/socios',
        component:Home,
        isPrivate: false,
        isHome: true,
        title: <BannerHome />,
    },
    {
        path:'/socios/ver-todos',
        component:AllPartners,
        isPrivate: false,
        isHome: true,
        title: (
            <DefaultBannerTitle
                title={""}
                subTitle={""}
                description={""}
            />
        )
    },
    {
        path:'/noticias',
        component:Home,
        isPrivate: false,
        isHome: true,
        title: <BannerHome />
    },
    {
        path:'/noticias/ver-todas',
        component:AllNews,
        isPrivate: false,
        isHome: true,
        title: <DefaultBannerTitle
            title={""}
            subTitle={""}
            description={""}
        />,
        children : [
            {
                path:':id',
                component:AllNews,
                isPrivate: false,
                isHome: true,
                title: <DefaultBannerTitle
                    title={""}
                    subTitle={""}
                    description={""}
                />,
            }
        ]
    },
    {
        path:'/contactanos',
        component:Contact,
        isPrivate: false,
        isHome: false,
        title: (
            <DefaultBannerTitle
                title={"Contáctenos"}
                subTitle={""}
                description={"AIEJA"}
            />
        )
    },
    {
        path:'/actividades',
        component:ActivitiesPage,
        isPrivate: false,
        isHome: false,
        title: (
            <DefaultBannerTitle
                title={"Actividades"}
                subTitle={"Conoce nuestras actividades"}
                description={"AEIJA"}
            />
        ),
        children: [
            {
                path:'responsabilidad-social',
                component:ActivitiesPage,
                isPrivate: false,
                isHome: false,
                title: (
                    <DefaultBannerTitle
                        title={"Actividades"}
                        subTitle={"Conoce nuestras actividades"}
                        description={"AEIJA"}
                    />
                )
            },
            {
                path:'comisiones',
                component:ActivitiesPage,
                isPrivate: false,
                isHome: false,
                title: (
                    <DefaultBannerTitle
                        title={"Actividades"}
                        subTitle={"Conoce nuestras actividades"}
                        description={"AEIJA"}
                    />
                )
            },
            {
                path:'notas-aieja',
                component:ActivitiesPage,
                isPrivate: false,
                isHome: false,
                title: (
                    <DefaultBannerTitle
                        title={"Actividades"}
                        subTitle={"Conoce nuestras actividades"}
                        description={"AEIJA"}
                    />
                )
            },
            {
                path:'casinos',
                component:ActivitiesPage,
                isPrivate: false,
                isHome: false,
                title: (
                    <DefaultBannerTitle
                        title={"Actividades"}
                        subTitle={"Conoce nuestras actividades"}
                        description={"AEIJA"}
                    />
                )
            },
        ]
    },
    {
        path:'/revista',
        component:Magazine,
        isPrivate: false,
        isHome: false,
        title: (
            <DefaultBannerTitle
                title={""}
                subTitle={""}
                description={""}
            />
        ),
        children: [
            {
                path:':magazineID',
                component:Magazine,
                isPrivate: false,
                isHome: false,
                title: (
                    <DefaultBannerTitle
                        title={""}
                        subTitle={""}
                        description={""}
                    />
                )
            }
        ]
    },
    {
        path:'/build',
        component:NotFound,
        isPrivate: true,
        isHome: false,
        title: (
            <DefaultBannerTitle
                title={"404"}
                subTitle={"Not Found"}
                description={"Esto es realmente embarazoso. El recurso que usted intenta acceder no está disponible en este momento. Quizá lo estuvo en el pasado y ha sido borrado o quizá has escrito la dirección web mal. Si recibes este error, comprueba que la dirección que has introducido es correcta y no le falta o sobra nada."}
            />
        )
    },
    {
        path:'/*',
        component:NotFound,
        isPrivate: false,
        isHome: false,
        title: (
            <DefaultBannerTitle
                title={"404"}
                subTitle={"Not Found"}
                description={"Esto es realmente embarazoso. El recurso que usted intenta acceder no está disponible en este momento. Quizá lo estuvo en el pasado y ha sido borrado o quizá has escrito la dirección web mal. Si recibes este error, comprueba que la dirección que has introducido es correcta y no le falta o sobra nada."}
            />
        )
    }
]