import React from "react";
import {AnimateBox} from "../../Components/GenericComponents/AnimateBox";
import {PartnerCard} from "./PartnerCard";
import {getPartnersData} from "../../Actions/PartnersActions";
import {useParameterizedQuery} from "react-fetching-library";
import {useVersionState} from "../../Contexts/ApiVersionProvider";
import {PartnerSkeleton} from "./PartnerSkeleton";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {Link} from "react-router-dom";
import {
    setPartnerSectionData,
    usePartnerSectionDispatch,
    usePartnerSectionState
} from "../../Contexts/PartnersSectionProvider";

export const Partners: React.FunctionComponent = () => {
    const [isLoadData, setIsLoadData] = React.useState(false);
    //const [partnerSection, setPartnerSection] = React.useState<PartnerSection>();

    const getPartnersDataAction = useParameterizedQuery(getPartnersData);

    const { data } = useVersionState()
    const version = React.useMemo( () => {
        return data?.versions?.partners
    }, [data] );

    const partnerSectionState = usePartnerSectionState()
    const partnerSectionDispatch = usePartnerSectionDispatch();

    const partnerSection = React.useMemo( () => {
        return partnerSectionState.data
    }, [partnerSectionState.data]);

    const partnerList = React.useMemo( ()=>{
        return partnerSection ? partnerSection.partners : [];
    }, [partnerSection] )

    const ElementOfPartner = React.useMemo( ()=>{
        return partnerList.sort((a, b) => a.order - b.order)
            .map(partner => <PartnerCard partner={partner} key={partner.id}/>);
        }, [partnerList] )

    const forceDownload = React.useMemo( () => {
        if (partnerSection === undefined || version === undefined) {
            setIsLoadData(false);
            return true
        }

        if (partnerSection.version !== version) {
            setIsLoadData(false);
            return true
        }

        setIsLoadData(true);
        return false
    }, [partnerSection, version] );

    React.useEffect(() => {
        if ( (forceDownload && version) || (version && version.length > 0 && !partnerSection && !isLoadData) ){
            setIsLoadData(true);

            if ( getPartnersDataAction.loading ) return;

            getPartnersDataAction.query(version).then(response => {
                if (response.payload) {
                    setPartnerSectionData(response.payload, partnerSectionDispatch).then();
                }
            });
        }
    }, [data, getPartnersDataAction, isLoadData, partnerSection, partnerSectionDispatch, version, forceDownload]);


    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 1500 },
            items: 4

        },
        desktop: {
            breakpoint: { max: 1500, min: 992 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 992, min: 768 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1
        }
    };

    if (partnerSection === undefined || getPartnersDataAction.loading) {
        return <PartnerSkeleton />;
    }

    return (
        <section>
            <div id="fh5co-project">
                <div className="container">
                    <div className="row row-pb-md">
                        <AnimateBox className="col-md-8 col-md-offset-2 text-left fh5co-heading  animate-box">
                            <span>{partnerSection.subtitle}</span>
                            <h2>{partnerSection.title}</h2>
                            <p>{partnerSection.description}</p>
                            <p>
                                <Link to={"/socios/ver-todos"}
                                      className="btn btn-primary"
                                      onClick={ _e => {
                                          window.scrollTo({ top: 0, behavior: 'smooth' });
                                      } }
                                >
                                    Ver todos
                                </Link>
                            </p>
                        </AnimateBox>
                    </div>

                    <div className="row">
                        <Carousel responsive={responsive}
                                  infinite
                                  autoPlay
                                  autoPlaySpeed={1200}
                                  arrows={false}
                        >
                            {ElementOfPartner}
                        </Carousel>
                    </div>
                </div>

            </div>
        </section>
    );
}