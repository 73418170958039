import React from "react";
import {NewsData} from "../../Actions/NewsAction";
import {useNavigate} from "react-router-dom";
import Moment from "react-moment";

interface ViewProps {
    isOpen: boolean;
    news: NewsData;
}

export const NewsModal: React.FunctionComponent<ViewProps> = (props) => {
    const {news, isOpen} = props;
    const navigate = useNavigate();

    const closeModal = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate('/noticias/ver-todas');
    }, [navigate]);


    const title = React.useMemo(() => {
        return  news.title;
    }, [news.title]);

    const contentStr = React.useMemo(() => {
        return  news.content;
    }, [news.content]);

    return (
        <div id="info-modal" className={`modal fade${isOpen ? ' in' : ''}`} tabIndex={-1} role="dialog"
             style={isOpen ? {display: "block"} : undefined}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={closeModal}><span
                            aria-hidden="true">&times;</span></button>
                        <h2 className="modal-title">{title}</h2>
                    </div>
                    <div className="modal-body">
                        <div dangerouslySetInnerHTML={{__html: contentStr}}/>
                    </div>

                    <div className="modal-footer">
                        <div className="col-xs-8" style={{textAlign:'left'}}>
                            <Moment format={'LL'} className="fh5co-date">{news.newsDate}</Moment>
                        </div>

                        <div className="col-xs-4">
                            <span>
                                <img src={news.author.image}
                                     alt={`Imagen del colaborador "${news.author.name}"`}
                                     className={'img-circle'}
                                     style={{width: '40px', height: '40px'}}
                                />
                            </span>
                            <span style={{marginLeft: '15px'}}>
                                <cite>{news.author.name}</cite>
                            </span>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
};