import React from "react";
import {AnimateBox} from "../../../Components/GenericComponents/AnimateBox";
import { SVGMap } from "react-svg-map";
import {getCasinos, StateData} from "../../../Actions/CasinosActions";
import {useParameterizedQuery} from "react-fetching-library";
import {useVersionState} from "../../../Contexts/ApiVersionProvider";
import {
    setCasinosSectionData,
    useCasinosSectionDispatch,
    useCasinosSectionState
} from "../../../Contexts/CasinosProvider";
import {CasinosSkeleton} from "./CasinosSkeleton";

export const CasinoMap : React.FunctionComponent = () => {
    const [isLoadData, setIsLoadData] = React.useState(false);

    const getCasinosAction = useParameterizedQuery(getCasinos);

    const { data } = useVersionState()
    const version = React.useMemo( () => {
        return data?.versions?.casinos
    }, [data] );

    const sectionState = useCasinosSectionState()
    const sectionDispatch = useCasinosSectionDispatch()

    const casinosSection = React.useMemo( ()=>{
        return sectionState.data
    } ,[sectionState.data] )

    const stateData = React.useMemo(()=>{
        if ( casinosSection ) {
            return casinosSection.map;
        }

        return [];
    }, [casinosSection])

    const forceDownload = React.useMemo( () => {
        if (casinosSection === undefined || version === undefined) {
            setIsLoadData(false);
            return true
        }

        if (casinosSection.version !== version) {
            setIsLoadData(false);
            return true
        }

        setIsLoadData(true);
        return false
    }, [casinosSection, version] );

    React.useEffect(() => {
        if( (forceDownload && version) || (version && version.length > 0 && !casinosSection && !isLoadData) ){
            setIsLoadData(true);

            if ( getCasinosAction.loading ) return;

            getCasinosAction.query(version).then(response => {
                if (response.payload) {
                    setCasinosSectionData(response.payload, sectionDispatch).then();
                }
            });
        }
    }, [forceDownload, data, getCasinosAction, isLoadData, casinosSection, version, sectionDispatch]);

    const [selectedState, setSelectedState] = React.useState<StateData>();

    const handleLocationClick = React.useCallback( (locationID:string) => {
        const state = stateData.find( state => state.id === locationID );

        if (state) {
            setSelectedState(state);
        }else{
            setSelectedState(undefined);
        }
    }, [stateData])

    if ( casinosSection === undefined || getCasinosAction.loading) return <CasinosSkeleton />;

    return <div id="fh5co-casino">
        <div className="container">

            <AnimateBox className="row animate-box" data-animate-effect="fadeInUp">
                <div className="col-md-8 col-md-offset-2 text-left fh5co-heading">
                    <span>{casinosSection.subtitle}</span>
                    <h2>{casinosSection.title}</h2>
                    <p>{casinosSection.description}</p>
                </div>
            </AnimateBox>

            <div className="row">
                <div className="col-sm-4"
                     style={{
                         height: '200px',
                         position: 'relative',
                         zIndex: 1000,
                         float: 'right',
                     }}
                >
                    <AnimateBox>
                        <div className="feature-center">
                            <span
                                className="counter-text"><strong>{(selectedState) ? selectedState.title : 'Selecione un estado'}</strong></span>
                            {
                                selectedState && (
                                    <React.Fragment>
                                    <span className="counter">
                                    <span className=" js-counter"
                                    data-from="0"
                                    data-to="90"
                                    data-speed="1500"
                                    data-refresh-interval="50">{(selectedState) ? selectedState.count : 0}</span></span>
                                    <span className="counter-label">casinos</span>
                                    </React.Fragment>
                                )
                            }
                </div>
            </AnimateBox>
        </div>
                <div className="col-sm-12"
                     style={{
                         marginTop: '-250px',
                     }}
                >
                    <SVGMap map={{
                        viewBox: "0 0 820 600",
                        locations: stateData.map(state => {
                            return {
                                id: state.id,
                                name: state.title,
                                path: state.d,
                            }
                        })
                    }}
                            onLocationClick={(l) => {
                                if (l.target) {
                                    handleLocationClick(l.target.id);
                                }
                            }}
                    />;
                </div>

            </div>
        </div>
    </div>

}