import React from "react";
import {motion} from "framer-motion";

interface ViewProps {
    children: React.ReactNode,
    className?: string
}

export const AnimateBox: React.FunctionComponent<ViewProps> = (props) => {
    const {children, className} = props;
    return (
        <motion.div
            className={className}
            initial={{opacity: 0, y: 100}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.8, ease: 'easeOut'}}
        >
            {children}
        </motion.div>
    )
}