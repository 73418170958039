import {AnimateBox} from "../../../Components/GenericComponents/AnimateBox";
import React from "react";
import {useNavigate} from "react-router-dom";

interface ViewProps {
    title: string;
    tag: string;
    id?: string;
    className? : string;
    icon?: string;
    noMore?: boolean;

    styleTitle?: React.CSSProperties;

    children?: React.ReactNode;
}

export const WhoWeAreCard: React.FunctionComponent<ViewProps> = (props) => {
    const {
        title, children,
        id, className, icon, styleTitle
    } = props;

    const navigate = useNavigate();

    const handleOnClick = React.useCallback( ( e:React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        const to = `/quienes-somos/${props.tag}/ver-mas`;
        navigate(to);
    }, [navigate, props.tag]);

    return (
        <div id={ id } className={className}>
            <AnimateBox className="feature-center animate-box" data-animate-effect="fadeInUp">
                {icon && (
                    <span className="icon">
                        <i className={`icon-${icon}`}></i>
                    </span>
                )}

                <h3 style={ styleTitle } dangerouslySetInnerHTML={{__html:title}} />
                <div className="row">
                    <div className="col-sm-12">
                        {children}
                    </div>
                </div>
                { !props.noMore && (
                        <div className="row">
                            <div className="col-sm-12">
                                <p> <a href="/modal" onClick={ handleOnClick }>Ver mas ...</a> </p>
                            </div>
                        </div> ) }
            </AnimateBox>
        </div>
)
}