import React from "react";
import {AnimateBox} from "../../Components/GenericComponents/AnimateBox";
import {useLocation} from "react-router-dom";

export const Contact: React.FunctionComponent = () => {
    const location = useLocation()
    const scrollTo = React.useCallback((selectorId: string) => {
        setTimeout(() => {
            const element = document.getElementById(selectorId);
            if (element) {
                element.scrollIntoView({behavior: 'smooth'});
            }
        }, 50);
    }, []);

    React.useEffect(() => {
        if (location.pathname === '/contactanos') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [location.pathname, scrollTo]);

    return (
        <React.Fragment>
            <div id="fh5co-contact">
                <div className="container">
                    <div className="row">
                        <AnimateBox className="col-md-5 col-md-push-1 animate-box">

                            <div className="fh5co-contact-info">
                                <h3>Información del contacto</h3>
                                <ul>
                                    <li className="phone"><a href="tel://5252547750">+52 52 54 77 50 </a></li>
                                    <li className="phone"><a href="tel://5252547760">+52 52 54 77 60 </a></li>
                                    <li className="email"><a href="mailto:info@aieja.org.mx">info@aieja.org.mx</a></li>
                                    <li className="url"><a href="https://www.aieja.org.mx/">aieja.org.mx</a></li>
                                </ul>
                            </div>

                        </AnimateBox>
                        <AnimateBox className="col-md-6 animate-box">
                            <form action="#">
                                <div className="row form-group">
                                    <div className="col-md-6">
                                        <label htmlFor="fname">Nombre(s)</label>
                                        <input type="text" id="fname" className="form-control"
                                               placeholder=""/>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="lname">Apellidos</label>
                                        <input type="text" id="lname" className="form-control"
                                               placeholder=""/>
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <label htmlFor="email">Email</label>
                                        <input type="text" id="email" className="form-control"
                                               placeholder=""/>
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <label htmlFor="subject">Asunto</label>
                                        <input type="text" id="subject" className="form-control"
                                               placeholder=""/>
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <label htmlFor="message">Mensaje</label>
                                        <textarea name="message" id="message" cols={30} rows={10}
                                                  className="form-control"
                                                  placeholder=""></textarea>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="submit" value="Enviar Mensaje" className="btn btn-lg btn-primary"/>
                                </div>

                            </form>
                        </AnimateBox>
                    </div>

                </div>
            </div>
        </React.Fragment>
    );
}