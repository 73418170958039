import {PartnerSection} from "../Actions/PartnersActions";
import {VersionData} from "./ApiVersionProvider";
import React from "react";

interface PartnerSectionDispatcher {
    type?: "SUCCESS" | "ERROR",
    payload?: PartnerSectionPayload
}

interface PartnerSectionPayload {
    data?: PartnerSection,
    loading?: boolean,
    errorMessage?: string,
    isLoaded?: boolean
}

// - Actions
export async function setPartnerSectionData( data: PartnerSection, dispatcher: React.Dispatch<PartnerSectionDispatcher>) {
    localStorage.setItem('partnerSection', JSON.stringify(data));
    dispatcher({ type: 'SUCCESS', payload: { data: data } });
}

const partnerSectionInitialState: PartnerSectionPayload = {
    data: undefined,
    loading: false,
    errorMessage: '',
    isLoaded: false
}

const partnerSectionData = localStorage.getItem('partnerSection');
if (partnerSectionData) {
    let localVersionData = localStorage.getItem('versions');
    if ( localVersionData) {
        const localData : VersionData = JSON.parse(localVersionData);
        if(localData.versions && localData.versions.partners ){
            const partnerSectionDataDataObj: PartnerSection = JSON.parse(partnerSectionData);
            if (partnerSectionDataDataObj.version === localData.versions.partners) {
                console.log('---> OK');
                partnerSectionInitialState.data = partnerSectionDataDataObj;
                partnerSectionInitialState.isLoaded = true;
            }
        }
    }
}

let partnerSectionReducer = (state: PartnerSectionPayload, action: PartnerSectionDispatcher): PartnerSectionPayload => {
    switch (action.type) {
        case 'SUCCESS':
            return { ...state, loading: false, data: action.payload?.data, isLoaded: true };
        case 'ERROR':
            return { ...state, loading: false, errorMessage: action.payload?.errorMessage };
        default:
            return state;
    }
}


const PartnerSectionStateContext = React.createContext<PartnerSectionPayload>({})
const PartnerSectionDispatchContext = React.createContext< React.Dispatch<PartnerSectionDispatcher> >(() => null)

export function usePartnerSectionState(){
    const context = React.useContext(PartnerSectionStateContext)
    if (context === undefined) {
        throw new Error("useAppsState must be used within a DiazarSuiteProvider");
    }

    return context;
}

export function usePartnerSectionDispatch(){
    const context = React.useContext(PartnerSectionDispatchContext)
    if (context === undefined) {
        throw new Error("useAppsDispatch must be used within a DiazarSuiteProvider");
    }

    return context;
}


interface ViewProps {
    children?: React.ReactNode | undefined
}

export const PartnerSectionProvider: React.FunctionComponent<ViewProps> = (props) => {
    const { children } = props;
    const [state, dispatch] = React.useReducer(partnerSectionReducer, partnerSectionInitialState);

    return (
        <PartnerSectionStateContext.Provider value={state}>
            <PartnerSectionDispatchContext.Provider value={dispatch}>
                { children }
            </PartnerSectionDispatchContext.Provider>
        </PartnerSectionStateContext.Provider>
    )
}