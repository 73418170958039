import {NewsSection} from "../Actions/NewsAction";
import {VersionData} from "./ApiVersionProvider";
import React from "react";

interface NewsSectionDispatcher {
    type?: "SUCCESS" | "ERROR",
    payload?: NewsSectionPayload
}

interface NewsSectionPayload {
    data?: NewsSection,
    loading?: boolean,
    errorMessage?: string,
    isLoaded?: boolean
}

// - Actions
export async function setNewsSectionData( data: NewsSection, dispatcher: React.Dispatch<NewsSectionDispatcher>) {
    localStorage.setItem('newsSection', JSON.stringify(data));
    dispatcher({ type: 'SUCCESS', payload: { data: data } });
}

const newsSectionInitialState: NewsSectionPayload = {
    data: undefined,
    loading: false,
    errorMessage: '',
    isLoaded: false
}

const newsSectionData = localStorage.getItem('newsSection');
if (newsSectionData) {
    let localVersionData = localStorage.getItem('versions');
    if ( localVersionData) {
        const localData : VersionData = JSON.parse(localVersionData);
        if(localData.versions && localData.versions.news ){
            const newsSectionDataDataObj: NewsSection = JSON.parse(newsSectionData);
            if (newsSectionDataDataObj.version === localData.versions.news) {
                console.log('---> OK');
                newsSectionInitialState.data = newsSectionDataDataObj;
                newsSectionInitialState.isLoaded = true;
            }
        }
    }
}

let newsSectionReducer = (state: NewsSectionPayload, action: NewsSectionDispatcher): NewsSectionPayload => {
    switch (action.type) {
        case 'SUCCESS':
            return { ...state, loading: false, data: action.payload?.data, isLoaded: true };
        case 'ERROR':
            return { ...state, loading: false, errorMessage: action.payload?.errorMessage };
        default:
            return state;
    }
}


const NewsSectionStateContext = React.createContext<NewsSectionPayload>({})
const NewsSectionDispatchContext = React.createContext< React.Dispatch<NewsSectionDispatcher> >(() => null)

export function useNewsSectionState(){
    const context = React.useContext(NewsSectionStateContext)
    if (context === undefined) {
        throw new Error("useAppsState must be used within a DiazarSuiteProvider");
    }

    return context;
}

export function useNewsSectionDispatch(){
    const context = React.useContext(NewsSectionDispatchContext)
    if (context === undefined) {
        throw new Error("useAppsDispatch must be used within a DiazarSuiteProvider");
    }

    return context;
}


interface ViewProps {
    children?: React.ReactNode | undefined
}

export const NewsSectionProvider: React.FunctionComponent<ViewProps> = (props) => {
    const { children } = props;
    const [state, dispatch] = React.useReducer(newsSectionReducer, newsSectionInitialState);

    return (
        <NewsSectionStateContext.Provider value={state}>
            <NewsSectionDispatchContext.Provider value={dispatch}>
                { children }
            </NewsSectionDispatchContext.Provider>
        </NewsSectionStateContext.Provider>
    )
}
